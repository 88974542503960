import React, { Component } from "react";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { getProfile } from "./js/main";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import PersonIcon from "@material-ui/icons/Person";
import CheckIcon from "@material-ui/icons/Check";
// import Divider from "@material-ui/core/Divider";
import Conf from "./Conf";
import axios from "axios";

export default class Staff extends Component {
  pageName = "staff";
  state = {
    cookie: JSON.parse(localStorage.getItem(Conf.cookieName)),
    count: 0,
    patrons: [],
    selPatron: {},
  };
  componentDidMount() {
    console.log(this.state.cookie);
    this.checkAuth();
  }

  async checkAuth() {
    var profile = await getProfile();
    if (
      profile.patron &&
      profile.patron.permission &&
      profile.patron.permission.indexOf(this.pageName) > -1
    ) {
      var patrons = await this.getPatron();
      console.log(profile);
      this.setState({
        patrons: patrons.result,
        count: patrons.count,
      });
    } else window.location = process.env.PUBLIC_URL + "/login";
  }
  selPatron(id) {
    var { patrons } = this.state;
    for (let patron of patrons) {
      if (patron._id === id) patron.selected = true;
      else patron.selected = false;
    }

    var i = patrons.findIndex((j) => j.selected === true);
    var selPatron = {};
    if (i > -1) selPatron = JSON.parse(JSON.stringify(patrons[i]));
    this.setState({ patrons, selPatron });
  }
  setPermission(abbrev) {
    var { selPatron, cookie } = this.state;
    var is_set = true;
    var i = selPatron.permission.findIndex((j) => j === abbrev);
    if (i > -1) is_set = false;

    if (selPatron._id) {
      axios
        .put(
          Conf.backendURL + "patron/staff/permission",
          { abbrev: abbrev, patron_id: selPatron._id, is_set: is_set },
          { headers: { Authorization: cookie.token } }
        )
        .then((res) => {
          if (res.data.permission) {
            var permission = JSON.parse(JSON.stringify(res.data.permission));
            selPatron.permission = permission;
            var { patrons } = this.state;
            i = patrons.findIndex((j) => j._id === selPatron._id);
            patrons[i].permission = permission;
            this.setState({ selPatron, patrons });
          }
        });
    }
  }
  getPatron() {
    return new Promise((resolve) => {
      var { cookie } = this.state;
      axios
        .post(
          Conf.backendURL + "patron/",
          { query: { is_admin: true } },
          { headers: { Authorization: cookie.token } }
        )
        .then((res) => {
          resolve(res.data);
        });
    });
  }
  checkPermission(abbrev) {
    var { selPatron } = this.state;
    var i = selPatron.permission.findIndex((j) => j === abbrev);
    if (i > -1) return true;
    else return false;
  }
  render() {
    const { patrons, count, selPatron } = this.state;
    return (
      <React.Fragment>
        <main className="content">
          <h1 className="pageHeader">
            <VerifiedUserIcon className="pageHeaderIcon" fontSize="large" />
            Staff
          </h1>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Paper square>
                <div
                  style={{
                    padding: "20px 20px",
                    fontSize: "1.2em",
                    fontWeight: "bold",
                  }}
                >
                  Total &nbsp; : &nbsp; {count}
                </div>
                <List component="nav">
                  {patrons.map((patron) => (
                    <ListItem
                      button
                      style={{
                        ...{ borderTop: "1px solid #ddd" },
                        ...(patron.selected === true
                          ? { background: "#dfd" }
                          : {}),
                      }}
                      key={patron._id}
                      onClick={() => this.selPatron(patron._id)}
                    >
                      <ListItemIcon>
                        <PersonIcon
                          style={{ fontSize: "3.5em", marginRight: "20px" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <div>
                          {patron.first_name} {patron.last_name}
                        </div>
                        <div style={{ fontSize: ".8em" }}>
                          {patron.patron_code}
                        </div>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper square>
                <List component="nav">
                  {Conf.menus.map((menu) => (
                    <div key={menu.name}>
                      <ListItem
                        button
                        onClick={() => this.setPermission(menu.abbrev)}
                        style={{ borderBottom: "1px solid #ddd" }}
                      >
                        <ListItemIcon
                          style={{ minWidth: "50px", width: "50px" }}
                        >
                          {selPatron._id &&
                          this.checkPermission(menu.abbrev) === true ? (
                            <CheckIcon
                              style={{ fontSize: "2em", color: "#0a0" }}
                            />
                          ) : (
                            <CheckBoxOutlineBlankIcon
                              style={{ fontSize: "2em" }}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText>{menu.name}</ListItemText>
                      </ListItem>
                    </div>
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    );
  }
}
