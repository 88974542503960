import Conf from "../Conf";
import axios from "axios";

export const search_key = [
  {
    label: "All",
    fields: [
      "title",
      "alternative",
      "creator",
      "author",
      "isbn",
      "issn",
      "identifier",
      "classification",
      "subject",
    ],
  },
  {
    label: "Title",
    fields: ["title", "alternative"],
  },
  {
    label: "Author",
    fields: ["creator", "author"],
  },
  {
    label: "Identifier",
    fields: ["isbn", "issn", "identifier", "classification"],
  },
  {
    label: "Subject",
    fields: ["subject"],
  },
];

export const getProfile = (idToken) => {
  return new Promise((resolve) => {
    /*
        var r = {
            patron: {
                _id: "5f12ffa08209e42ca89b819f",
                usn: "pratchaya.cmu@gmail.com",
                is_admin: true,
                permission: ["catalog", "checkout", "dayclose", "patron", "staff", "location"],
                patron_code: "10000"
            }
        }
        resolve(r);
        */
    // Production
    if (!idToken || idToken === "") {
      var cookie = localStorage.getItem(Conf.cookieName);
      cookie = JSON.parse(cookie);
      idToken = "";
      if (cookie && cookie.token) idToken = cookie.token;
    }

    if (idToken && idToken !== "") {
      axios
        .get(Conf.backendURL + "auth/me", {
          headers: { Authorization: idToken },
        })
        .then((res) => {
          resolve(res.data);
        });
    } else resolve({ error: "Access Denied" });
  });
};
