import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getProfile } from './js/main';
import Conf from './Conf';
import axios from 'axios';

export default class DayClose extends Component {
    pageName = "dayclose";
    state = {
        profile: null,
        weekday: 0,
        month: 0,
        year: 0,
        day_count: 0,
        currentYear: 0,
        calendar: []
    }
    componentDidMount() {
        this.checkAuth();
    }

    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }
    getMonth(month, year) {
        var d = new Date();
        var { currentYear } = this.state;
        if (!month) {
            month = d.getMonth() + 1;
        }
        if (!year) {
            year = d.getFullYear();
        }
        if (currentYear === 0) {
            currentYear = d.getFullYear();
        }
        var day_count = this.daysInMonth(month, year);
        d = new Date(year + "-" + month + "-01");
        var weekday = d.getDay();
        var calendar = [];
        var line = 0;
        var c = 0;
        for (let i = 0; i < weekday; i++, c++) calendar.push({ id: c, value: "", line: line, is_close: false });
        for (let i = 1; i <= day_count; i++, c++) {
            if (c % 7 === 0) line++;
            calendar.push({ id: c, value: i, line: line, is_close: false });
        }

        this.setState({
            weekday,
            month,
            year,
            currentYear,
            day_count,
            calendar
        }, () => this.getDayCloses());
    }
    getDayCloses() {
        var { month, year } = this.state;
        axios.post(Conf.backendURL + "dayclose", { query: { month: month, year: year } }).then(res => {
            this.setDayCloses(res.data);
        });
    }
    setDayCloses(closes) {
        var { calendar } = this.state;
        for (let r of closes) {
            let i = calendar.findIndex(j => j.value === r.day);
            if (i > -1) calendar[i].is_close = true;
        }
        this.setState({ calendar })
    }
    async checkAuth() {
        var profile = await getProfile();
        if (profile.patron && profile.patron.permission && profile.patron.permission.indexOf(this.pageName) > -1) {
            this.getMonth();
        } else window.location = process.env.PUBLIC_URL + "/login";

    }
    selectDay(id) {
        var { calendar } = this.state;
        var i = calendar.findIndex(j => j.id === id);
        calendar[i].is_close = !calendar[i].is_close;
        this.setState({ calendar })
    }
    checkSelectedDay(day) {
        if (day.is_close === true) {
            return ({
                background: '#fcc'
            })
        } else {
            return ({})
        }
    }
    changeCalendar() {
        var month = parseInt(document.getElementById("month").value);
        var year = parseInt(document.getElementById("year").value);
        this.getMonth(month, year);
    }
    save() {
        var { calendar, month, year } = this.state;
        var cookie = localStorage.getItem(Conf.cookieName);
        cookie = JSON.parse(cookie);
        var closes = calendar.filter(j => j.is_close === true).map(c => { return ({ 'day': c.value, 'month': month, 'year': year }) });
        if (closes.length === 0) closes = [{ month: month, year: year }]
        axios.put(Conf.backendURL + "dayclose", closes, { headers: { Authorization: cookie.token } }).then(res => {
            if (res.data.error) alert(res.data.error);
            else alert("Save Completed");
        });
    }
    render() {
        const { month, year, currentYear, calendar } = this.state;
        const styles = {
            tdDayNo: {
                fontSize: '1.3em',
                textAlign: 'center',
                padding: '10px 5px'
            },
            dayNo: {
                color: '#333',
                cursor: 'pointer',
                width: '40px',
                height: '40px',
                verticalAlign: 'middle',
                margin: 'auto',
                paddingTop: '7px',
                borderRadius: '50%'
            }
        }
        return <React.Fragment>
            <main className="content" >
                <h1 className="pageHeader">
                    <EventBusyIcon className="pageHeaderIcon" fontSize="large" />
                    Day Close
                </h1>
                <div style={{ margin: '20px auto 20px' }}>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-age-native-simple">Month</InputLabel>
                        <Select
                            native
                            value={month}
                            onChange={() => this.changeCalendar()}
                            label="Month"
                            style={{ width: '200px', marginRight: '10px' }}
                            inputProps={{
                                name: 'month',
                                id: 'month',
                            }}
                        >
                            {
                                Conf.months.map(month => <option value={month.id} key={month.id}>{month.name}</option>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-age-native-simple">Year</InputLabel>
                        <Select
                            native
                            value={year}
                            onChange={() => this.changeCalendar()}
                            label="Year"
                            style={{ width: '120px' }}
                            inputProps={{
                                name: 'year',
                                id: 'year',
                            }}
                        >
                            {
                                currentYear && [currentYear, currentYear + 1, currentYear + 2].map(y => <option value={y} key={y}>{y}</option>)
                            }
                        </Select>
                    </FormControl>
                </div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {
                                    Conf.dayName.map(day => <TableCell key={day}>{day}</TableCell>)
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                [0, 1, 2, 3, 4, 5, 6].map(row => <TableRow key={row}>
                                    {
                                        calendar.filter(j => j.line === row).map(day => <TableCell style={styles.tdDayNo} key={day.id}>
                                            {day.value !== "" && <div onClick={() => this.selectDay(day.id)} href="#!" style={{ ...styles.dayNo, ...this.checkSelectedDay(day) }}>
                                                {day.value}
                                            </div>}
                                        </TableCell>
                                        )
                                    }
                                </TableRow>)
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ paddingTop: '20px', textAlign: 'right' }}>
                    <Button variant="contained" size="large" color="primary"
                        startIcon={<SaveIcon />}
                        onClick={() => this.save()}>
                        Save Calendar
                    </Button>
                </div>
            </main >

        </React.Fragment >
    }
}
