import React from "react";
// import axios from 'axios';
import {
  Link,
  Paper,
  Grid,
  Typography,
  CssBaseline,
  Avatar,
} from "@material-ui/core";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/app";
import "firebase/auth";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { withStyles } from "@material-ui/core/styles";
import Conf from "./Conf";
import { getProfile } from "./js/main";

const useStyles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(" + process.env.PUBLIC_URL + "/img/login.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(5, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
});

firebase.initializeApp(Conf.fbConfig);

class Login extends React.Component {
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google and Facebook as auth providers.
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      // Avoid redirects after sign-in.
      //signInSuccess: () => false,
      signInSuccessWithAuthResult: () => false,
    },
  };
  constructor(props) {
    super(props);
    firebase.auth().signOut();
  }
  componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      console.log(user);
      if (user) {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(async (idToken) => {
            var profile = await getProfile(idToken);
            if (!profile.patron || profile.patron.is_admin !== true) {
              alert("Permission Denied");
              window.location = process.env.PUBLIC_URL + "/login";
              localStorage.removeItem(Conf.cookieName);
            } else {
              console.log(
                firebase.auth().currentUser.displayName,
                firebase.auth().currentUser.email
              );
              localStorage.setItem(
                Conf.cookieName,
                JSON.stringify({
                  token: idToken,
                })
              );
              window.location = Conf.frontendURL + "checkout";
            }
            // Send token to your backend via HTTPS
            // ...
            // console.log(idToken);
          })
          .catch(function (error) {
            // Handle error
          });
        // var permission = true;
        // this.setState({
        //     isSignedIn:!!user,
        //     hasPermission:permission
        // })
      }
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={7} className={classes.image} />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography
                style={{ color: "#666", fontSize: "1em", lineHeight: "50px" }}
              >
                Sign in
              </Typography>
              <h1>Happy Digital Library</h1>
              <div style={{ marginTop: "20px" }}>
                <StyledFirebaseAuth
                  uiConfig={this.uiConfig}
                  firebaseAuth={firebase.auth()}
                />
              </div>
              <div
                style={{ position: "absolute", right: "5px", bottom: "10px" }}
              >
                <Copyright />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ fontSize: ".9em" }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Happy Digital Development
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default withStyles(useStyles)(Login);
