import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import QRCode from "qrcode.react";
import axios from "axios";
import Conf from "../Conf";

export default class Barcode extends Component {
  state = {
    items: [],
    tr: [],
  };
  async componentDidMount() {
    var items = await this.getItems();
    items = items.items;
    // var pageNo = 0;
    var trNo = 0;
    var tdNo = 0;
    var tr = [];
    for (let item of items) {
      item.trNo = trNo;
      item.tdNo = tdNo;
      tdNo++;
      if (tdNo === 5) {
        tdNo = 0;
        tr.push(trNo);
        trNo++;
      }
    }
    tr.push(trNo);
    this.setState({ items, tr }, () => window.print());
  }

  getItems() {
    return new Promise((resolve) => {
      var cookie = localStorage.getItem(Conf.cookieName);
      cookie = JSON.parse(cookie);
      /*
          ["5fba12ca397b221254b86183", "5fba12cb397b221254b86189", "5fba12cb397b221254b86193", "5fba12cd397b221254b8620f", "5fba12cb397b221254b86192", "5fba12cd397b221254b8620e", "5fba12cb397b221254b86196"] 
      */
      axios
        .post(
          Conf.backendURL + "item",
          {
            query: { _id: { $in: cookie.itemCart } },
          },
          {
            headers: { Authorization: cookie.token },
          }
        )
        .then((res) => {
          console.log(res.data);
          resolve(res.data);
        });
    });
  }
  render() {
    const styles = {
      tdCell: {
        width: "3.8cm",
        border: "1px solid #eee",
        cellSpacing: "0px",
        cellPadding: "0px",
      },
    };
    const { tr, items } = this.state;
    const showCallNumber = (no) => {
      var arr = no.split(" ");
      return arr;
    };
    return (
      <div
        style={{
          width: "20cm",
          margin: "auto",
        }}
      >
        <table style={{ borderSpacing: "0px" }}>
          <tbody>
            {tr.map((t) => (
              <tr key={t}>
                {items
                  .filter((j) => j.trNo === t)
                  .map((item) => (
                    <td style={styles.tdCell} key={item._id}>
                      <Grid container>
                        <Grid
                          item
                          style={{
                            width: "1.5cm",
                            textAlign: "center",
                            height: "2.5cm",
                          }}
                        >
                          <QRCode
                            value={item.barcode}
                            renderAs="canvas"
                            size={500}
                            style={{
                              marginTop: ".7cm",
                              width: "1cm",
                              height: "1cm",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          style={{
                            height: "2.5cm",
                            paddingTop: ".3cm",
                            paddingLeft: ".3cm",
                            fontSize: ".8rem",
                          }}
                        >
                          {showCallNumber("TK 2000 30 AB").map((no) => (
                            <div>{no}</div>
                          ))}
                        </Grid>
                      </Grid>
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
