import React from "react";
import {
  List,
  Paper,
  Grid,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import MenuBookIcon from "@material-ui/icons/MenuBook";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PageFooter from "./PageFooter";

export default class ShowSearch extends React.Component {
  callback_url = this.props.url || "";
  state = {};
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.getResult();
    }
  }
  componentDidMount() {
    this.getResult();
  }
  getResult() {
    var result = this.props.searchResult.result || [];
    var skip = this.props.skip || 0;
    var selectItems = this.props.selectItems || [];
    var c = 0;
    for (let r of result) {
      var count_item = 0;
      for (let item of r.items) {
        let i = selectItems.findIndex((j) => j === item._id);
        if (i > -1) result[c].items[count_item].selected = true;
        count_item++;
      }
      c++;
    }

    this.setState({
      result,
      skip,
    });
  }
  render() {
    const styles = {
      icon_container: {
        float: "left",
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        background: "#80cbc4",
        color: "#fff",
        textAlign: "center",
        // paddingTop: "7px",
      },
    };
    const { result, skip } = this.state;
    const { onSelect, onSelectItem } = this.props;
    function showField(elements, name) {
      var i = elements.findIndex((j) => j.name === name);
      if (i > -1) {
        return elements[i].value;
      } else return "";
    }
    function selectBib(id) {
      if (!onSelectItem && onSelect) onSelect(id);
    }
    return (
      <div>
        <Paper square>
          <List component="nav">
            <NavigateResult
              skip={skip}
              count={this.props.searchResult.count}
              onChangePage={(start) => this.props.onChangePage(start)}
            />
            {result && result.length === 0 && (
              <div
                style={{
                  textAlign: "center",
                  lineHeight: "200px",
                  color: "#999",
                }}
              >
                No Match Found
              </div>
            )}
            {result &&
              result.map((bib) => (
                <React.Fragment key={bib._id}>
                  <ListItem
                    button={onSelectItem ? false : true}
                    onClick={() => selectBib(bib._id)}
                    /* button onClick={() => onSelect(bib._id)} */
                  >
                    {/* <ListItem component="a" href={`${this.callback_url}/${bib._id}`}> */}
                    <ListItemText>
                      {onSelectItem ? (
                        <IconButton
                          // style={{ padding: "5px 5px" }}
                          style={{ float: "left" }}
                          onClick={() => onSelect(bib._id)}
                          color="primary"
                        >
                          <MenuBookIcon style={{ fontSize: "1.5em" }} />
                        </IconButton>
                      ) : (
                        <div style={styles.icon_container}>
                          <MenuBookIcon
                            style={{
                              fontSize: "2em",
                              marginTop: "7px",
                            }}
                          />
                        </div>
                      )}
                      <div style={{ paddingLeft: "70px" }}>
                        <div style={{ fontWeight: "bold", color: "#333" }}>
                          {showField(bib.elements, "title")}
                        </div>
                        <div style={{ fontSize: ".9em", color: "#666" }}>
                          {showField(bib.elements, "creator")}
                          {bib.items && (
                            <TableContainer
                              component={Paper}
                              style={{ marginTop: "5px" }}
                            >
                              <Table>
                                <TableHead>
                                  <TableRow
                                    style={{
                                      background: "#26a69a",
                                    }}
                                  >
                                    {onSelectItem && (
                                      <TableCell
                                        style={{
                                          padding: "5px 5px",
                                          width: "60px",
                                          color: "#fff",
                                        }}
                                      ></TableCell>
                                    )}

                                    <TableCell
                                      style={{
                                        padding: "5px 5px",
                                        color: "#fff",
                                      }}
                                    >
                                      Barcode
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        padding: "5px 5px",
                                        color: "#fff",
                                      }}
                                    >
                                      Call Number
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        padding: "5px 5px",
                                        color: "#fff",
                                      }}
                                    >
                                      Location
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {bib.items.map((item, key) => (
                                    <ShowItem
                                      key={key}
                                      item={item}
                                      onSelectItem={
                                        onSelectItem
                                          ? (selected) =>
                                              onSelectItem(item, selected)
                                          : false
                                      }
                                    />
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </div>
                      </div>
                    </ListItemText>
                  </ListItem>
                  <Divider light />
                </React.Fragment>
              ))}
          </List>
        </Paper>
        <PageFooter />
      </div>
    );
  }
}
class ShowItem extends React.Component {
  state = {
    item: this.props.item,
  };
  selectItem() {
    var { item } = this.state;
    if (item.selected === true) item.selected = false;
    else item.selected = true;
    this.setState({ item });
    this.props.onSelectItem(item.selected);
  }
  render() {
    const { onSelectItem } = this.props;
    const { item } = this.state;
    const styles = {
      cell: { padding: "5px 5px" },
    };
    return (
      <TableRow>
        {onSelectItem && (
          <TableCell align="center" style={{ padding: "0px 5px" }}>
            <IconButton
              style={{ padding: "5px 5px" }}
              onClick={() => this.selectItem()}
            >
              {item.selected === true ? (
                <CheckCircleIcon style={{ color: "#3F51B5" }} />
              ) : (
                <RadioButtonUncheckedIcon />
              )}
            </IconButton>
          </TableCell>
        )}
        <TableCell align="center" style={styles.cell}>
          {item.barcode}
        </TableCell>
        <TableCell align="center" style={styles.cell}>
          {item.call_no}
        </TableCell>
        <TableCell align="center" style={styles.cell}>
          {item.location.name}
        </TableCell>
      </TableRow>
    );
  }
}

class NavigateResult extends React.Component {
  render() {
    const limit = 50;
    const { count, onChangePage, skip } = this.props;
    function getPage() {
      var r = [];
      for (let page = 1; page < count / limit; page++) {
        r.push({ start: (page - 1) * limit, page: page });
      }
      if (r.length === 0) r.push({ start: 0, page: 1 });
      return r;
    }
    return (
      <React.Fragment>
        <ListItem>
          <ListItemText style={{ padding: "10px 10px" }}>
            <Grid container>
              <Grid item xs={12} md={9} lg={10}>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: "8px",
                    paddingBottom: "15px",
                  }}
                >
                  Found : {count} record(s)
                </div>
              </Grid>
              <Grid item xs={12} md={3} lg={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="page" style={{ lineHeight: "0px" }}>
                    Page
                  </InputLabel>
                  <Select
                    native
                    value={skip}
                    onChange={() =>
                      onChangePage(
                        parseInt(document.getElementById("page").value)
                      )
                    }
                    label="Page"
                    inputProps={{
                      name: "page",
                      id: "page",
                      style: { padding: "10px 10px" },
                    }}
                  >
                    {getPage().map((p) => (
                      <option key={p.page} value={p.start}>
                        {p.page}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </ListItemText>
        </ListItem>
        <Divider light />
      </React.Fragment>
    );
  }
}
