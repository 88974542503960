import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import OPAC from "./OPAC";
import Biblio from "./Biblio";
import Login from "./Login";
import Checkout from "./Checkout";
import Checkin from "./Checkin";
import Catalog from "./Catalog";
import DayClose from "./DayClose";
import BookCart from "./BookCart";
import Patron from "./Patron";
import Location from "./Location";
// import Circulation from './Circulation';
import Circulation from "./report/Circulation";
import Barcode from "./report/Barcode";
import RptPatron from "./report/RptPatron";
import Staff from "./Staff";
import Header from "./components/Header";

function App() {
  const noHeader = ["login", "barcode", "barcode?", "opac", "biblio", ""];
  var currentPage = "";
  var arr = window.location.href.split("/");
  if (arr.indexOf("opac") > -1) {
    currentPage = "opac";
  } else {
    currentPage = arr[arr.length - 1];
  }
  return (
    <div>
      <Router>
        {noHeader.indexOf(currentPage) < 0 && <Header />}
        <Route
          path={`${process.env.PUBLIC_URL}/report/barcode`}
          exact
          component={() => <Barcode />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/`}
          exact
          component={(props) => <OPAC {...props} />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/opac/search`}
          exact
          component={(props) => <OPAC {...props} />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/opac/biblio/:id`}
          exact
          component={(props) => <Biblio {...props} />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/opac/biblio/:id/:query`}
          exact
          component={(props) => <Biblio {...props} />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/opac/search/:query`}
          exact
          component={(props) => <OPAC {...props} />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/login`}
          exact
          component={() => <Login />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/checkout`}
          exact
          component={() => <Checkout />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/checkin`}
          exact
          component={() => <Checkin />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/bookcart`}
          exact
          component={() => <BookCart />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/catalog`}
          exact
          component={() => <Catalog />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/dayclose`}
          exact
          component={() => <DayClose />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/patron`}
          exact
          component={() => <Patron />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/patron/:id`}
          exact
          component={(props) => <Patron {...props} />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/staff`}
          exact
          component={() => <Staff />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/location`}
          exact
          component={() => <Location />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/catalog/:id`}
          exact
          component={(props) => <Catalog {...props} />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/report/circulation/`}
          exact
          component={(props) => <Circulation {...props} />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/report/patron/`}
          exact
          component={(props) => <RptPatron {...props} />}
        />
        {/* <Route path={`${process.env.PUBLIC_URL}/workplace/catalog`} exact component={() => <Auth />} /> 
        <Route path={`${process.env.PUBLIC_URL}/workplace/circulation`} exact component={(props) => <Bill {...props} />} />  */}
      </Router>
    </div>
  );
}

export default App;
