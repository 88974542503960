import React from "react";
import ShowSearch from "./components/ShowSearch";
import { getProfile } from "./js/main";
import {
  Paper,
  Select,
  FormControl,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Grid,
  InputAdornment,
} from "@material-ui/core";

import LinearProgress from "@material-ui/core/LinearProgress";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import EditIcon from "@material-ui/icons/Edit";
import UndoIcon from "@material-ui/icons/Undo";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Conf from "./Conf";
import axios from "axios";

export default class Catalog extends React.Component {
  biblio_id =
    this.props.match && this.props.match.params && this.props.match.params.id;
  pageName = "catalog";
  state = {
    is_loading: false,
    showSearch: false,
    searchResult: [],
    metadata: [],
    biblio: {
      metadata: [],
    },
    // items: [],
    require: [],
    selectItems: [],
  };
  componentDidMount() {
    this.checkAuth();
  }
  async checkAuth() {
    var profile = await getProfile();
    var metadata = await this.getMetadata();
    if (
      profile.patron &&
      profile.patron.permission &&
      profile.patron.permission.indexOf(this.pageName) > -1
    ) {
      var require = await this.getRequire(metadata);
      var biblio = {
        metadata: [],
      };
      // var items = [];
      if (this.biblio_id && this.biblio_id !== "") {
        biblio = await this.getBiblio(metadata);
        // items = await this.getItems();
      }
      if (!biblio) window.location = process.env.PUBLIC_URL + "/catalog";
      else {
        var cookie = localStorage.getItem(Conf.cookieName);
        cookie = JSON.parse(cookie);
        var selectItems = cookie.itemCart || [];
        this.setState(
          {
            profile: profile,
            metadata: metadata,
            require: require,
            biblio: biblio,
            selectItems: selectItems,
          },
          () => {
            this.setDefaultValue();
          }
        );
      }
    } else window.location = process.env.PUBLIC_URL + "/login";
  }
  setDefaultValue() {
    var { metadata, biblio } = this.state;
    if (biblio._id) {
      metadata
        .filter((j) => j.childs.length === 0)
        .map((data) => {
          var i = biblio.metadata.findIndex((j) => j.name === data.name);
          if (i > -1)
            document.getElementById("description-" + data._id).value =
              biblio.metadata[i].value;
          return false;
        });
    }
  }
  // getItems() {
  //     return new Promise(resolve => {
  //         axios.get(Conf.backendURL + 'item/biblio/' + this.biblio_id).then(async res => {
  //             resolve(res.data)
  //         });
  //     });

  // }
  getBiblio(metadata) {
    return new Promise((resolve) => {
      axios
        .get(Conf.backendURL + "biblio/" + this.biblio_id)
        .then(async (res) => {
          if (res.data) {
            res.data.metadata = JSON.parse(JSON.stringify(res.data.elements));
            delete res.data.elements;
            var key = 0;
            for (let data of res.data.metadata) {
              let i = metadata.findIndex((j) => j.name === data.name);
              if (i < 0) {
                let child_of = await this.getChildId(
                  data.metadata_id,
                  metadata
                );
                res.data.metadata[key].child_of = child_of;
              }
              key++;
            }
            // console.log(metadata.filter(j=>!j.child_of));
          }
          resolve(res.data);
        });
    });
  }
  getChildId(id, metadata) {
    return new Promise((resolve) => {
      var metadata_id = null;
      for (let data of metadata.filter((j) => j.childs.length > 0)) {
        let i = data.childs.findIndex((j) => j._id === id);
        if (i > -1) {
          metadata_id = data.childs[i].child_of;
          break;
        }
      }
      resolve(metadata_id);
    });
  }
  getRequire(metadata) {
    return new Promise((resolve) => {
      var require = [];
      for (let m of metadata) {
        if (m.require === true) require.push(m.name);
        for (let child of m.childs) {
          if (child.require === true) require.push(child.name);
        }
      }
      resolve(require);
    });
  }
  getMetadata() {
    return new Promise((resolve) => {
      axios.get(Conf.backendURL + "metadata").then((res) => {
        resolve(res.data);
      });
    });
  }
  updateBiblio(field, value) {
    var { biblio, metadata } = this.state;
    var obj = {
      name: field.name,
      value: value,
      child_of: field.child_of,
      metadata_id: field._id,
    };
    var name = field.name;
    var i = metadata.findIndex((j) => j._id === field.child_of);
    if (field.child_of) {
      var k = metadata[i].childs.findIndex((j) => j.name === name);
      if (metadata[i].childs[k].is_multi === false) {
        biblio.metadata = biblio.metadata.filter((j) => j.name !== field.name);
      }
      biblio.metadata.push(obj);
    } else {
      i = metadata.findIndex((j) => j.name === name);
      if (metadata[i].is_multi === false) {
        biblio.metadata = biblio.metadata.filter((j) => j.name !== field.name);
      }
      biblio.metadata.push(obj);
    }
    this.setState({ biblio });
  }
  removeMetadata(name, value) {
    var { biblio } = this.state;
    // biblio.metadata = biblio.metadata.filter(j=>j.name!==name && j.value!==value);
    var i = biblio.metadata.findIndex(
      (j) => j.name === name && j.value === value
    );
    if (i > -1) {
      if (biblio.metadata[i]._id) biblio.metadata[i].status = "delete";
      else biblio.metadata.splice(i, 1);
      this.setState({ biblio });
    }
  }

  async saveBiblio() {
    var { biblio, metadata } = this.state;
    var fields = metadata.filter((j) => j.childs.length === 0);
    for (var field of fields) {
      let name = field.name;
      let id = null;
      if (biblio._id) {
        let i = biblio.metadata.findIndex((j) => j.name === name);
        if (i > -1) id = biblio.metadata[i]._id;
      }
      var obj = {
        _id: id,
        name: name,
        value: document.getElementById("description-" + field._id).value,
        metadata_id: field._id,
      };
      if (obj.value !== "") {
        if (field.is_multi === false)
          biblio.metadata = biblio.metadata.filter((j) => j.name !== name);
        biblio.metadata.push(obj);
      } else {
        biblio.metadata = biblio.metadata.filter((j) => j.name !== name);
      }
    }
    var require = await this.checkRequire(biblio.metadata);
    if (require === false) alert("Please fill completed information");
    else {
      var cookie = localStorage.getItem(Conf.cookieName);
      cookie = JSON.parse(cookie);
      console.log(biblio);
      axios
        .put(Conf.backendURL + "biblio", biblio, {
          headers: { Authorization: cookie.token },
        })
        .then((res) => {
          if (res.data.error) {
            alert(res.data.error);
            window.location = process.env.PUBLIC_URL + "/login";
          } else {
            alert("Save Completed");
            this.checkAuth();
          }
        });
    }
  }
  async saveBiblio_old() {
    var { biblio, metadata } = this.state;
    var fields = metadata.filter((j) => j.childs.length === 0);
    for (var field of fields) {
      let name = field.name;
      let id = null;
      if (biblio._id) {
        let i = biblio.metadata.findIndex((j) => j.name === name);
        if (i > -1) id = biblio.metadata[i]._id;
      }
      var obj = {
        _id: id,
        name: name,
        value: document.getElementById("description-" + field._id).value,
        metadata_id: field._id,
      };
      if (obj.value !== "") {
        if (field.is_multi === false)
          biblio.metadata = biblio.metadata.filter((j) => j.name !== name);
        biblio.metadata.push(obj);
      } else {
        console.log(field._id, obj);
        biblio.metadata = biblio.metadata.filter((j) => j.name !== name);
      }
    }
    var require = await this.checkRequire(biblio.metadata);
    if (require === false) alert("Please fill completed information");
    else {
      var cookie = localStorage.getItem(Conf.cookieName);
      cookie = JSON.parse(cookie);
      console.log(biblio);
      axios
        .put(Conf.backendURL + "biblio", biblio, {
          headers: { Authorization: cookie.token },
        })
        .then((res) => {
          if (res.data.error) {
            alert(res.data.error);
            window.location = process.env.PUBLIC_URL + "/login";
          } else {
            alert("Save Completed");
            this.checkAuth();
          }
        });
    }
  }
  saveItem(item) {
    item.biblio_id = this.biblio_id;
    var cookie = localStorage.getItem(Conf.cookieName);
    cookie = JSON.parse(cookie);
    axios
      .put(Conf.backendURL + "item", item, {
        headers: { Authorization: cookie.token },
      })
      .then((res) => {
        if (res.data.error) alert(res.data.error);
        else {
          var { biblio } = this.state;
          if (item._id) {
            var i = biblio.items.findIndex((j) => j._id === item._id);
            if (i > -1) biblio.items[i] = res.data;
          } else {
            biblio.items.push(res.data);
          }
          this.setState({ biblio });
        }
      });
  }
  deleteItem(id) {
    if (window.confirm("Confirm to delete")) {
      var cookie = localStorage.getItem(Conf.cookieName);
      cookie = JSON.parse(cookie);
      axios
        .delete(Conf.backendURL + "item/" + id, {
          headers: { Authorization: cookie.token },
        })
        .then(async (res) => {
          if (res.data.error) alert(res.data.error);
          else {
            var { biblio } = this.state;
            biblio.items = await biblio.items.filter((j) => j._id !== id);
            this.setState({ biblio });
          }
        });
    }
  }
  checkRequire(metadata) {
    return new Promise((resolve) => {
      var { require } = this.state;
      var save = true;
      for (let r of require) {
        if (metadata.findIndex((j) => j.name === r) < 0) {
          save = false;
          break;
        }
      }
      resolve(save);
    });
  }
  goSearch(e) {
    if (e.key === "Enter") {
      var keyword = document.getElementById("keyword").value.trim();
      this.renderSearch(keyword, 0);
    }
  }
  renderSearch(keyword, start) {
    if (keyword === "") {
      this.setState({ showSearch: false }, () => this.setDefaultValue());
    } else {
      this.setState({ is_loading: true });
      var query = { keyword: { $regex: ".*" + keyword + ".*" } };
      console.log(query);
      axios
        .post(Conf.backendURL + "biblio", { query: query, start: start })
        .then((res) => {
          console.log(res.data);
          this.setState({
            showSearch: true,
            searchResult: res.data,
            is_loading: false,
          });
        });
    }
  }
  searchGoBib(id) {
    window.location = process.env.PUBLIC_URL + "/catalog/" + id;
  }
  searchChangePage(start) {
    var keyword = document.getElementById("keyword").value.trim();
    this.renderSearch(keyword, start);
  }
  onSelectItem(id, is_select) {
    console.log(is_select);
    var { selectItems } = this.state;
    if (is_select === true) {
      var i = selectItems.findIndex((j) => j === id);
      if (i < 0) selectItems.push(id);
    } else selectItems = selectItems.filter((j) => j !== id);
    this.setState({ selectItems }, () => {
      var cookie = localStorage.getItem(Conf.cookieName);
      cookie = JSON.parse(cookie);
      cookie.itemCart = selectItems;
      localStorage.setItem(Conf.cookieName, JSON.stringify(cookie));
    });
  }
  render() {
    const {
      profile,
      showSearch,
      metadata,
      biblio,
      searchResult,
      selectItems,
      is_loading,
    } = this.state;
    return (
      <React.Fragment>
        {profile && (
          <React.Fragment>
            <main className="content">
              <Grid container>
                <Grid item xs={12} md={7} lg={8}>
                  <h1 className="pageHeader">
                    <MenuBookIcon className="pageHeaderIcon" fontSize="large" />
                    Bibliography
                  </h1>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  lg={4}
                  style={{ padding: "20px 10px" }}
                >
                  <TextField
                    id="keyword"
                    label="Search"
                    variant="outlined"
                    fullWidth
                    onKeyUp={(e) => this.goSearch(e)}
                    // value="ก"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              {is_loading === true && (
                <div>
                  <LinearProgress />
                </div>
              )}
              {showSearch === false ? (
                <div>
                  <BibForm
                    metadata={metadata} /* biblio={biblio} */
                    updateBiblio={(field, value) =>
                      this.updateBiblio(field, value)
                    }
                    biblio={biblio}
                    saveBiblio={() => this.saveBiblio()}
                    removeMetadata={(name, value) =>
                      this.removeMetadata(name, value)
                    }
                  />
                  {this.biblio_id && (
                    <ItemForm
                      items={biblio.items}
                      selectItems={selectItems}
                      onSave={(item) => this.saveItem(item)}
                      onDelete={(id) => this.deleteItem(id)}
                      onSelectItem={(id, is_select) =>
                        this.onSelectItem(id, is_select)
                      }
                    />
                  )}
                </div>
              ) : (
                <ShowSearch
                  searchResult={searchResult}
                  // url={`${process.env.PUBLIC_URL}/catalog`}
                  onSelect={(id) => this.searchGoBib(id)}
                  onChangePage={(page) => this.searchChangePage(page)}
                  onSelectItem={(item, selected) =>
                    this.onSelectItem(item._id, selected)
                  }
                  selectItems={selectItems}
                />
              )}
            </main>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

class BibForm extends React.Component {
  render() {
    const { metadata, updateBiblio, biblio } = this.props;
    return (
      <React.Fragment>
        <Paper square>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "200px" }}>Field</TableCell>
                  <TableCell style={{ width: "200px" }}>Sub Field</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell style={{ width: "50px" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {metadata.map((field) => (
                  <React.Fragment key={field._id}>
                    <RowField
                      field={field}
                      updateBiblio={(field, value) =>
                        updateBiblio(field, value)
                      }
                    />
                    {field.childs.length > 0 &&
                      biblio.metadata
                        .filter(
                          (j) =>
                            (j.name === field.name ||
                              j.child_of === field._id) &&
                            j.status !== "delete"
                        )
                        .map((data, key) => {
                          return (
                            <RowMetadata
                              key={key}
                              metadata={data}
                              removeMetadata={(name, value) =>
                                this.props.removeMetadata(name, value)
                              }
                            />
                          );
                        })}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ textAlign: "right", padding: "20px 10px" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveAltIcon />}
              onClick={() => this.props.saveBiblio()}
            >
              Save Bibliography
            </Button>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}
class RowField extends React.Component {
  state = {
    description: this.props.field.description,
    currentField: {},
  };
  componentDidMount() {
    this.setCurrentField(this.props.field);
  }
  // updateField(e){
  //     var {currentField} = this.state;
  //     if(!currentField.child_of && currentField.childs.length===0) this.props.updateBiblio(currentField,e.target.value);
  // }
  addMetadata() {
    var { field } = this.props;
    var { currentField } = this.state;
    var value = document.getElementById("description-" + field._id).value;
    if (value !== "") {
      this.props.updateBiblio(currentField, value);
      document.getElementById("description-" + field._id).value = "";
    }
  }
  setCurrentField(field) {
    field = JSON.parse(JSON.stringify(field));
    this.setState({ currentField: field });
  }
  getPlaceholder(e) {
    var txt = this.props.field.description;
    var { field } = this.props;
    if (e.target.value !== "") {
      var i = field.childs.findIndex((j) => j.name === e.target.value);
      this.setCurrentField(field.childs[i]);
      txt += " -- " + field.childs[i].description;
    } else this.setCurrentField(field);
    document.getElementById("description-" + field._id).value = "";
    this.setState({ description: txt });
  }
  getDefaultSubField(subfields) {
    var i = subfields.findIndex((j) => j.is_default === true);
    if (i > -1) return subfields[i].name;
    else return "";
  }
  render() {
    const { field } = this.props;
    const { description } = this.state;
    return (
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          style={{
            padding: "5px 5px",
            textTransform: "capitalize",
            fontWeight: "bold",
          }}
        >
          {field.name}{" "}
          {field.require === true && <span style={{ color: "#f00" }}>*</span>}
        </TableCell>
        <TableCell align="center" style={{ padding: "5px 5px" }}>
          {field.childs.length > 0 && (
            <FormControl variant="outlined" fullWidth>
              <Select
                native
                //   value={state.age}
                onChange={(e) => this.getPlaceholder(e)}
                // style={{padding:'0px 0px'}}
                defaultValue={this.getDefaultSubField(field.childs)}
                inputProps={{
                  id: field.name,
                  style: { padding: "10px 10px" },
                }}
              >
                <option aria-label="None" value="" />
                {field.childs.map((child) => {
                  return (
                    <option
                      key={child._id}
                      value={child.name}
                      // selected={child.is_default === true ? true : false}
                    >
                      {child.name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </TableCell>
        <TableCell align="center" style={{ padding: "5px 5px" }}>
          <TextField
            id={`description-${field._id}`}
            placeholder={description}
            variant="outlined"
            fullWidth
            size="small" /* onChange={e=>this.updateField(e)} */
          />
        </TableCell>
        <TableCell align="center" style={{ padding: "0px 0px" }}>
          {field.childs.length > 0 && (
            <IconButton
              aria-label="add"
              color="primary"
              style={{ padding: "5px 5px" }}
              onClick={() => this.addMetadata()}
            >
              <AddCircleIcon fontSize="large" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

class RowMetadata extends React.Component {
  render() {
    const { metadata } = this.props;
    return (
      <TableRow style={{ background: "#fafafa" }}>
        <TableCell align="center"></TableCell>
        <TableCell align="center" style={{ padding: "5px 5px" }}>
          <div style={{ fontWeight: "400" }}>
            {metadata.child_of ? metadata.name : "-"}
          </div>
        </TableCell>
        <TableCell style={{ padding: "5px 5px" }}>
          <div>{metadata.value}</div>
        </TableCell>
        <TableCell align="center" style={{ padding: "0px 0px" }}>
          <IconButton
            aria-label="delete"
            color="secondary"
            style={{ padding: "5px 5px" }}
            onClick={() =>
              this.props.removeMetadata(metadata.name, metadata.value)
            }
          >
            <HighlightOffIcon fontSize="large" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

class ItemForm extends React.Component {
  state = {
    default_location: null,
    locations: [],
    current_item: {},
    items: [],
  };
  // constructor(props) {
  //     super(props);
  //     console.log(this.props.items)
  // }
  async componentDidMount() {
    var locations = await this.getLocation();
    var items = this.props.items;
    var default_location = null;
    var i = locations.findIndex((j) => j.is_default === true);
    if (i > -1) default_location = locations[i]._id;
    this.setState({ items, locations, default_location }, () =>
      this.clearForm()
    );
  }
  componentDidUpdate() {
    const { items } = this.state;
    if (items !== this.props.items) {
      this.setState({ items: this.props.items }, () => this.clearSelect());
    }
  }
  getLocation() {
    return new Promise((resolve) => {
      axios.get(Conf.backendURL + "location").then((res) => {
        resolve(res.data);
      });
    });
  }
  selectItem(id) {
    const { items } = this.state;
    var i = items.findIndex((j) => j._id === id);
    var current_item = {};
    if (i > -1) current_item = items[i];
    this.setState({ current_item }, () => this.setForm());
  }
  setForm() {
    var { current_item } = this.state;
    if (current_item._id) {
      if (current_item.location) {
        document.getElementById("itemLocation").value =
          current_item.location._id;
        console.log(current_item.location.name);
      }
      document.getElementById("itemCallNo").value = current_item.call_no.trim();
      document.getElementById(
        "itemBarcode"
      ).value = current_item.barcode.trim();
    }
  }
  clearSelect() {
    this.setState({ current_item: {} }, () => this.clearForm());
  }
  clearForm() {
    if (this.state.default_location)
      document.getElementById(
        "itemLocation"
      ).value = this.state.default_location;
    else document.getElementById("itemLocation").value = "";
    document.getElementById("itemCallNo").value = "";
    document.getElementById("itemBarcode").value = "";
  }
  /*
  getLocationName(id) {
    var { locations } = this.state;
    var i = locations.findIndex((j) => j._id === id);
    if (i > -1) return locations[i].name;
    else return "";
  }
  */
  addItem() {
    var { current_item } = this.state;
    var item = {
      location: document.getElementById("itemLocation").value.trim(),
      call_no: document.getElementById("itemCallNo").value.trim(),
      barcode: document.getElementById("itemBarcode").value.trim(),
    };
    if (current_item._id) item._id = current_item._id;
    if (item.call_no === "" || item.location === "" || item.barcode === "")
      alert("Please fill complete information");
    else {
      this.props.onSave(item);
      this.clearSelect();
    }
  }
  render() {
    const { items, current_item, locations } = this.state;
    const { selectItems } = this.props;
    return (
      <div>
        <h1 className="pageHeader">
          <MenuBookIcon className="pageHeaderIcon" fontSize="large" />
          Items
        </h1>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "60px" }}></TableCell>
                <TableCell style={{ width: "25%" }}>Location</TableCell>
                <TableCell>Call No.</TableCell>
                <TableCell>Barcode</TableCell>
                <TableCell style={{ width: "60px" }}>*</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items &&
                items.map((item) => (
                  <TableRow
                    key={item._id}
                    style={
                      current_item._id === item._id
                        ? { background: "#efe" }
                        : { background: "#fff" }
                    }
                  >
                    <TableCell style={{ padding: "0px 5px" }} align="center">
                      {current_item._id === item._id ? (
                        <IconButton
                          aria-label="delete"
                          color="secondary"
                          style={{ padding: "5px 5px" }}
                          onClick={() => this.props.onDelete(item._id)}
                        >
                          <HighlightOffIcon fontSize="large" />
                        </IconButton>
                      ) : selectItems.findIndex((j) => j === item._id) < 0 ? (
                        <IconButton
                          aria-label="select"
                          style={{ padding: "5px 5px" }}
                          onClick={() =>
                            this.props.onSelectItem(item._id, true)
                          }
                        >
                          <RadioButtonUncheckedIcon fontSize="large" />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="select"
                          color="inherit"
                          style={{ padding: "5px 5px", color: "#8c8" }}
                          onClick={() =>
                            this.props.onSelectItem(item._id, false)
                          }
                        >
                          <CheckCircleIcon fontSize="large" />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {item.location.name}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {item.call_no}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {item.barcode}
                    </TableCell>
                    <TableCell style={{ padding: "0px 5px" }} align="center">
                      {current_item._id !== item._id ? (
                        <IconButton
                          aria-label="Edit"
                          color="primary"
                          style={{ padding: "5px 5px" }}
                          onClick={() => this.selectItem(item._id)}
                        >
                          <EditIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="Undo"
                          style={{ color: "#00a152" }}
                          onClick={() => this.clearSelect()}
                        >
                          <UndoIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>*</TableCell>
                <TableCell style={{ padding: "6px 5px" }}>
                  {/* <TextField id="itemLocation" placeholder="Location" variant="outlined"
                                    fullWidth size="small"
                                    inputProps={{
                                        style: { textAlign: 'center' }
                                    }}
                                /> */}

                  <FormControl variant="outlined" fullWidth>
                    <Select
                      native
                      // value="5f5611fdd46aca175055599a"
                      // onChange={handleChange}
                      inputProps={{
                        id: "itemLocation",
                        style: { padding: "11px 3px" },
                      }}
                    >
                      {locations &&
                        locations.map((location) => (
                          <option value={location._id} key={location._id}>
                            {location.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell style={{ padding: "5px 5px" }}>
                  <TextField
                    id="itemCallNo"
                    placeholder="Call No."
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      style: { textAlign: "center" },
                    }}
                  />
                </TableCell>
                <TableCell style={{ padding: "5px 5px" }}>
                  <TextField
                    id="itemBarcode"
                    placeholder="Barcode"
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      style: { textAlign: "center" },
                    }}
                  />
                </TableCell>
                <TableCell style={{ padding: "5px 5px" }} align="center">
                  <IconButton
                    aria-label="add"
                    color="primary"
                    style={{ padding: "5px 5px" }}
                    onClick={() => this.addItem()}
                  >
                    <AddCircleIcon fontSize="large" />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
