const on_dev = false;
module.exports = {
  // frontendURL: "http://localhost:3000/",
  // backendURL: "http://localhost:3002/",
  hdsBackend: "https://wds-vercel.vercel.app/",
  backendURL:
    on_dev === true
      ? "http://localhost:3002/"
      : "https://hdl-pratchaya009.vercel.app/",
  frontendURL:
    on_dev === true ? "http://localhost:3000/" : "https://hdl.tonkla.ac.th/",

  fbKey: "AIzaSyCr1Z1WoR-tWAvx0uMVdowq2RUgHHna6dk",
  fbAuthDomain: "tonkla-a1378.firebaseapp.com",
  cookieName: "hdl_storage",
  fbConfig: {
    // apiKey: Conf.fbKey,
    // authDomain: Conf.fbAuthDomain,

    apiKey: "AIzaSyCr1Z1WoR-tWAvx0uMVdowq2RUgHHna6dk",
    authDomain: "tonkla-a1378.firebaseapp.com",
    databaseURL: "https://tonkla-a1378.firebaseio.com",
    projectId: "tonkla-a1378",
    storageBucket: "tonkla-a1378.appspot.com",
    messagingSenderId: "1094459691353",
    appId: "1:1094459691353:web:b8cd3f058d13e837babc48",
  },

  dayName: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  months: [
    { id: 1, name: "January", abbrev: "JAN" },
    { id: 2, name: "February", abbrev: "FEB" },
    { id: 3, name: "March", abbrev: "MAR" },
    { id: 4, name: "April", abbrev: "APR" },
    { id: 5, name: "May", abbrev: "MAY" },
    { id: 6, name: "June", abbrev: "JUN" },
    { id: 7, name: "July", abbrev: "JUL" },
    { id: 8, name: "August", abbrev: "AUG" },
    { id: 9, name: "September", abbrev: "SEP" },
    { id: 10, name: "October", abbrev: "OCT" },
    { id: 11, name: "November", abbrev: "NOV" },
    { id: 12, name: "December", abbrev: "DEC" },
  ],
  menus: [
    {
      head_id: 10,
      name: "Check Out",
      link: "checkout",
      abbrev: "checkout",
      icon: "call_missed_outgoing_icon",
    },
    {
      head_id: 10,
      name: "Check In",
      link: "checkin",
      abbrev: "checkin",
      icon: "call_missed_icon",
    },
    {
      head_id: 20,
      name: "Patrons",
      link: "patron",
      abbrev: "patron",
      icon: "people_icon",
    },
    {
      head_id: 20,
      name: "Bibliography",
      link: "catalog",
      abbrev: "catalog",
      icon: "menu_book_icon",
    },
    {
      head_id: 20,
      name: "Book Cart",
      link: "bookcart",
      abbrev: "bookcart",
      icon: "shopping_cart",
    },
    {
      head_id: 20,
      name: "Staff",
      link: "staff",
      abbrev: "staff",
      icon: "verified_user_icon",
    },
    {
      head_id: 30,
      name: "Circuration Report",
      link: "report/circulation",
      abbrev: "report_circulation",
      icon: "playlist_add_check_icon",
    },
    // {
    //   head_id: 30,
    //   name: "Patrons Report",
    //   link: "report/patron",
    //   abbrev: "patron_report",
    //   icon: "assignment_ind_icon",
    // },
    // {
    //   head_id: 30,
    //   name: "Item Report",
    //   link: "",
    //   abbrev: "item_report",
    //   icon: "description_icon",
    // },
    {
      head_id: 40,
      name: "Day Close",
      link: "dayclose",
      abbrev: "dayclose",
      icon: "event_busy_icon",
    },
    {
      head_id: 40,
      name: "Location",
      link: "location",
      abbrev: "location",
      icon: "location_on_icon",
    },
  ],
  menuHeads: [
    { id: 10, name: "Circulation" },
    { id: 20, name: "Resources" },
    { id: 30, name: "Report" },
    { id: 40, name: "Configuration" },
  ],
};
