import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { getProfile } from "./js/main";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";

import CropFreeIcon from "@material-ui/icons/CropFree";
import CallMissedOutgoingIcon from "@material-ui/icons/CallMissedOutgoing";
import CheckIcon from "@material-ui/icons/Check";
import CallMissedIcon from "@material-ui/icons/CallMissed";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Badge from "@material-ui/core/Badge";

import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Conf from "./Conf";
import axios from "axios";

export default class Checkout extends Component {
  pageName = "checkout";
  state = {
    cookie: JSON.parse(localStorage.getItem(Conf.cookieName)),
    tabNo: 0,
    checkout: [],
    hold: [],
    patron: {
      patron_code: "",
      fullname: "-",
      address: "-",
      email: "-",
      note: "-",
    },
    types: [],
  };
  componentDidMount() {
    this.checkAuth();
    document.getElementById("barcode").value = "";
  }
  async checkAuth() {
    var profile = await getProfile();
    if (
      profile.patron &&
      profile.patron.permission &&
      profile.patron.permission.indexOf(this.pageName) > -1
    ) {
      var types = await this.getTypes();
      this.setState({ types });
    } else window.location = process.env.PUBLIC_URL + "/login";
  }
  getHold(id) {
    return new Promise((resolve) => {
      axios.post(Conf.backendURL + "circulation/hold/" + id).then((res) => {
        console.log(res.data);
        resolve(res.data);
      });
    });
  }
  getTypes() {
    return new Promise((resolve) => {
      axios.get(Conf.backendURL + "patron/type").then((res) => {
        resolve(res.data);
      });
    });
  }
  changeTab(event, newValue) {
    this.setState({ tabNo: newValue });
  }
  scan(e) {
    var barcode = e.target.value.trim();
    if (e.key === "Enter" && barcode !== "") {
      e.target.select();
      if (!this.state.patron._id) this.getPatron(barcode);
      else this.postCheckout(barcode);
    }
  }
  postCheckout(barcode) {
    var { cookie, patron } = this.state;
    var send = {
      _id: patron._id,
    };
    axios
      .put(Conf.backendURL + "circulation/checkout/" + barcode, send, {
        headers: { Authorization: cookie.token },
      })
      .then(async (res) => {
        if (res.data.error) alert(res.data.error);
        else {
          var { checkout } = this.state;
          var hold = await this.getHold(patron._id);
          checkout.push(res.data);
          this.setState({ checkout, hold });
        }
      });
  }
  getPatron(barcode) {
    var { cookie } = this.state;
    axios
      .post(
        Conf.backendURL + "patron",
        { query: { patron_code: barcode } },
        { headers: { Authorization: cookie.token } }
      )
      .then(async (res) => {
        if (res.data.error) alert(res.data.error);
        else if (res.data.result && res.data.result.length > 0) {
          var { types } = this.state;
          var result = res.data.result[0];
          result.fullname =
            result.prefix + " " + result.first_name + " " + result.last_name;
          var i = types.findIndex((j) => j._id === result.type);
          if (i > -1) {
            result.type_name = types[i].name;
            result.checkout_day = types[i].checkout_day;
            result.limit_item = types[i].limit_item;
          }
          var hold = await this.getHold(result._id);
          this.setState({ patron: result, hold: hold });
          document.getElementById("barcode").value = "";
        }
      });
  }
  checkin(id) {
    var { cookie, hold } = this.state;
    axios
      .put(
        Conf.backendURL + "circulation/checkin/" + id,
        {},
        { headers: { Authorization: cookie.token } }
      )
      .then((res) => {
        if (res.data.error) alert(res.data.error);
        else {
          console.log(res.data);
          hold = hold.filter((j) => j._id !== id);
          this.setState({ hold });
        }
      });
  }
  render() {
    const styles = {
      patronLabel: { fontSize: ".7em", fontWeight: "500" },
      patronField: { minHeight: "30px" },
    };
    const { patron, tabNo, checkout, hold } = this.state;
    return (
      <React.Fragment>
        <main className="content">
          <h1 className="pageHeader">
            <CallMissedOutgoingIcon
              className="pageHeaderIcon"
              fontSize="large"
            />
            Checkout
          </h1>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              {!patron._id ? (
                <div
                  style={{
                    fontSize: "1.5em",
                    marginBottom: "20px",
                    fontWeight: "400",
                  }}
                >
                  Scan Patron Code
                </div>
              ) : (
                <div
                  style={{
                    fontSize: "1.5em",
                    marginBottom: "20px",
                    fontWeight: "400",
                  }}
                >
                  Scan Item Code
                </div>
              )}
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="barcode">Barcode</InputLabel>
                <OutlinedInput
                  id="barcode"
                  onKeyUp={(e) => this.scan(e)}
                  onClick={() => document.getElementById("barcode").select()}
                  startAdornment={
                    <CropFreeIcon
                      position="start"
                      style={{ marginRight: "7px" }}
                    />
                  }
                  labelWidth={62}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={8} style={{ paddingLeft: "20px" }}>
              <Grid container>
                <Grid item xs={12}>
                  <div style={styles.patronLabel}>Patron Name</div>
                  <div style={styles.patronField}>
                    {patron.fullname}&nbsp;
                    {patron._id && <> ({patron.type_name})</>}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={styles.patronLabel}>Address</div>
                  <div style={styles.patronField}>{patron.address || "-"}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={styles.patronLabel}>Phone Number</div>
                  <div style={styles.patronField}>{patron.phone_no || "-"}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={styles.patronLabel}>e-mail</div>
                  <div style={styles.patronField}>{patron.email || "-"}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={styles.patronLabel}>Note</div>
                  <div style={styles.patronField}>{patron.note || "-"}</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Paper square style={{ padding: "10px 10px" }}>
            <Tabs
              value={tabNo}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, newValue) => this.changeTab(event, newValue)}
            >
              <Tab label="Checkout" />
              <Tab
                label={
                  <Badge badgeContent={hold.length} color="secondary">
                    <div style={{ width: "70px" }}>Hold</div>
                  </Badge>
                }
              />
            </Tabs>
            {!patron._id ? (
              <div style={{ lineHeight: "80px", textAlign: "center" }}>
                Not found patron
              </div>
            ) : (
              <>
                {tabNo === 0 ? (
                  <CheckoutTable patron={patron} checkout={checkout} />
                ) : (
                  <HoldTable hold={hold} onCheckin={(id) => this.checkin(id)} />
                )}
              </>
            )}
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

class CheckoutTable extends Component {
  render() {
    const { checkout } = this.props;
    return (
      <div>
        {checkout.length === 0 ? (
          <div style={{ lineHeight: "80px", textAlign: "center" }}>
            Not found item
          </div>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan="2">Title</TableCell>
                  <TableCell>Due</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {checkout.map((item, key) => (
                  <TableRow key={key}>
                    <TableCell style={{ textAlign: "center", width: "60px" }}>
                      <CheckIcon size="large" style={{ color: "#0a0" }} />
                    </TableCell>
                    <TableCell>{item.metadata.title}</TableCell>
                    <TableCell align="center" style={{ width: "120px" }}>
                      {item.due_date}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  }
}

class HoldTable extends Component {
  render() {
    const { hold, onCheckin } = this.props;
    return (
      <div>
        {hold.length === 0 ? (
          <div style={{ lineHeight: "80px", textAlign: "center" }}>
            Not found item
          </div>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "60px" }}>No.</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell style={{ width: "120px" }}>Checkout</TableCell>
                  <TableCell style={{ width: "120px" }}>Due</TableCell>
                  <TableCell style={{ width: "60px" }}>Checkin</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hold.map((item, key) => (
                  <TableRow key={key}>
                    <TableCell style={{ textAlign: "center" }}>
                      {key + 1}
                    </TableCell>
                    <TableCell>
                      {
                        item.item.biblio_id.elements.filter(
                          (j) => j.name === "title"
                        )[0].value
                      }
                    </TableCell>
                    <TableCell align="center">{item.checkout_date}</TableCell>
                    <TableCell align="center">{item.due_date}</TableCell>
                    <TableCell align="center" style={{ padding: "0px 0px" }}>
                      <IconButton
                        aria-label="checkin"
                        style={{ padding: "5px 5px" }}
                        onClick={() => onCheckin(item._id)}
                      >
                        <CallMissedIcon
                          style={{ color: "#0a0" }}
                          fontSize="large"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  }
}
