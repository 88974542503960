import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
// import CallMissedIcon from "@material-ui/icons/CallMissed";
// import EventBusyIcon from "@material-ui/icons/EventBusy";
// import PeopleIcon from "@material-ui/icons/People";
// import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
// import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Link } from "react-router-dom";

// import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
// import DescriptionIcon from "@material-ui/icons/Description";
// import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
// import CallMissedOutgoingIcon from "@material-ui/icons/CallMissedOutgoing";
// import MenuBookIcon from "@material-ui/icons/MenuBook";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

import Conf from "../Conf";

export default class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ResponsiveDrawer />
      </React.Fragment>
    );
  }
}

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  listIcon: {
    minWidth: "40px",
  },
  listItem: {
    padding: "3px 15px",
    color: "#333",
  },
  listItemHeader: {
    borderBottom: "1px solid #ddd",
    padding: "0px 3px",
    color: "#333",
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      {Conf.menuHeads.map((head) => (
        <div key={head.id}>
          <List className={classes.listItemHeader}>
            <ListItem style={{ padding: "0px 10px" }}>
              <ListItemText>
                <div style={{ fontSize: "1.2rem", fontWeight: "400" }}>
                  {head.name}
                </div>
              </ListItemText>
            </ListItem>
          </List>

          <List style={{ padding: "0px 0px", borderBottom: "1px solid #ddd" }}>
            {Conf.menus
              .filter((j) => j.head_id === head.id)
              .map((menu, key) => (
                <ListItem
                  key={key}
                  button
                  className={classes.listItem}
                  component={Link}
                  to={`${process.env.PUBLIC_URL}/${menu.link}`}
                >
                  <ListItemIcon className={classes.listIcon}>
                    <div>
                      <Icon>{menu.icon}</Icon>
                    </div>
                  </ListItemIcon>
                  <ListItemText>
                    <div style={{ fontSize: "1em" }}>{menu.name}</div>
                  </ListItemText>
                </ListItem>
              ))}
          </List>
        </div>
      ))}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Happy Digital Library
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
