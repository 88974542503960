import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

import { getProfile } from "../js/main";
// import FormControl from '@material-ui/core/FormControl';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import InputLabel from '@material-ui/core/InputLabel';
// import IconButton from '@material-ui/core/IconButton';

// import CropFreeIcon from '@material-ui/icons/CropFree';
// import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
// import CheckIcon from '@material-ui/icons/Check';
// import CallMissedIcon from '@material-ui/icons/CallMissed';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Badge from '@material-ui/core/Badge';

// import Paper from '@material-ui/core/Paper';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Conf from "../Conf";
import axios from "axios";

export default class Checkout extends Component {
  pageName = "report_circulation";
  state = {
    cookie: JSON.parse(localStorage.getItem(Conf.cookieName)),
    checkout: [],
    pages: [{ no: 1, skip: 0 }],
    limit_item: 50,
  };
  componentDidMount() {
    this.checkAuth();
  }
  async checkAuth() {
    var profile = await getProfile();
    if (
      profile.patron &&
      profile.patron.permission &&
      profile.patron.permission.indexOf(this.pageName) > -1
    ) {
      var checkout = await this.getCheckout();
      this.getPatrons();
      var pages = await this.getPage(checkout.count);
      this.setState({ checkout, pages });
    } else window.location = process.env.PUBLIC_URL + "/login";
  }
  getPage(count) {
    return new Promise((resolve) => {
      var limit = this.state.limit_item;
      var no = 1;
      var pages = [];
      for (var skip = 0; skip < count; skip += limit) {
        pages.push({ no: no, skip: skip });
      }
      resolve(pages);
    });
  }
  getPatrons() {
    return new Promise((resolve) => {
      var { cookie } = this.state;
      axios
        .post(
          Conf.backendURL + "circulation/hold/patron",
          {},
          { headers: { Authorization: cookie.token } }
        )
        .then((res) => {
          console.log(res.data);
          resolve(res.data);
        });
    });
  }
  getCheckout() {
    return new Promise((resolve) => {
      var { cookie } = this.state;
      axios
        .post(
          Conf.backendURL + "item",
          { query: { checkout: { $ne: null } } },
          { headers: { Authorization: cookie.token } }
        )
        .then((res) => {
          resolve(res.data);
        });
    });
  }
  getBibInfo(item, field) {
    var elements = item.biblio_id.elements;
    var i = elements.findIndex((j) => j.name === field);
    if (i > -1) {
      return elements[i].value;
    } else return "";
  }
  render() {
    const { checkout, pages } = this.state;
    return (
      <React.Fragment>
        <main className="content">
          <Grid container style={{ marginTop: "30px" }}>
            <Grid
              item
              xs={6}
              style={{
                fontSize: "1.3em",
                padding: "15px 15px",
                fontWeight: "400",
              }}
            >
              Result : {checkout.count && checkout.count.toLocaleString()}{" "}
              item(s)
            </Grid>

            <Grid item xs={6} style={{ textAlign: "right" }}>
              <FormControl variant="outlined">
                <Select
                  native
                  // value="5f5611fdd46aca175055599a"
                  // onChange={handleChange}
                  inputProps={{
                    id: "itemLocation",
                    style: { padding: "11px 20px", width: "80px" },
                  }}
                >
                  {pages.map((page) => (
                    <option value={page.skip} key={page.no}>
                      {page.no}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "60px" }}>No.</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell style={{ width: "250px" }}>Patron</TableCell>
                  <TableCell style={{ width: "100px" }}>Checkout</TableCell>
                  <TableCell style={{ width: "100px" }}>Due</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {checkout.items &&
                  checkout.items.map((item, key) => (
                    <TableRow key={key}>
                      <TableCell style={{ textAlign: "center" }}>
                        <div>{key + 1}</div>
                      </TableCell>
                      <TableCell style={{ padding: "3px 3px" }}>
                        <div>{this.getBibInfo(item, "title")}</div>
                        <div style={{ fontSize: ".8em", color: "#777" }}>
                          {item.barcode}
                        </div>
                      </TableCell>
                      <TableCell style={{ padding: "3px 3px" }}>
                        <div>
                          {item.checkout.patron.first_name}{" "}
                          {item.checkout.patron.last_name}
                        </div>
                        <div style={{ fontSize: ".8em", color: "#777" }}>
                          {item.checkout.patron.patron_code}
                        </div>
                      </TableCell>
                      <TableCell align="center" style={{ width: "120px" }}>
                        <div>{item.checkout.checkout_date}</div>
                      </TableCell>
                      <TableCell align="center" style={{ width: "120px" }}>
                        <div
                          style={
                            item.checkout.checkout_date < item.checkout.due_date
                              ? {}
                              : { color: "#f00" }
                          }
                        >
                          {item.checkout.due_date}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </main>
      </React.Fragment>
    );
  }
}
