import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Conf from "../Conf";
import axios from "axios";

export default class ImportPatronDialog extends Component {
  state = {
    form: {
      import_type: "1",
      remove_other: false,
      is_load: false,
    },
  };
  changeForm(e) {
    var { form } = this.state;
    var id = e.target.id;
    if (id === "remove_other") form[id] = !form[id];
    else form[id] = e.target.value;
    this.setState({ form });
  }
  onLoad(status) {
    this.setState({ is_load: status });
  }
  render() {
    const { is_open, closeSyncDialog, type_id } = this.props;
    const { form, is_load } = this.state;
    return (
      <div>
        <AlertDialog
          is_open={is_open}
          type_id={type_id}
          form={form}
          is_load={is_load}
          onChangeForm={(e) => this.changeForm(e)}
          closeSyncDialog={() => closeSyncDialog()}
          onLoad={(status) => this.onLoad(status)}
          //   onDialogClose=
        />
      </div>
    );
  }
}

function AlertDialog(props) {
  function goSync() {
    var cookie = JSON.parse(localStorage.getItem(Conf.cookieName));
    if (cookie.token) {
      var { form, type_id, onLoad } = props;
      var url = Conf.backendURL + "patron/sync/hds/student";
      if (form.import_type === "2")
        url = Conf.backendURL + "patron/sync/hds/curator";
      form.type_id = type_id;
      onLoad(true);
      axios
        .put(url, form, {
          headers: { Authorization: cookie.token },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.error) {
            alert(res.data.error);
            onLoad(false);
            closeSyncDialog();
            if (res.data.signin_timeout)
              window.location = process.env.PUBLIC_URL + "/login";
          } else {
            onLoad(false);
            closeSyncDialog();
          }
        });
    }
  }
  const { is_open, form, onChangeForm, closeSyncDialog, is_load } = props;
  return (
    <div>
      <Dialog open={is_open} maxWidth="xs" fullWidth={true}>
        <DialogTitle id="alert-dialog-title">{"Import from HDS"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="import_type" shrink>
                Sync with
              </InputLabel>
              <Select
                native
                value={form.import_type}
                onChange={(e) => onChangeForm(e)}
                label="Sync with"
                id="import_type"
                inputProps={{
                  id: "import_type",
                }}
              >
                <option value={1}>Student</option>
                <option value={2}>Curator</option>
                {/* <option value={3}>Staff</option> */}
              </Select>
            </FormControl>
            <div style={{ padding: "5px 5px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="remove_other"
                    color="primary"
                    checked={form.remove_other}
                    onChange={(e) => onChangeForm(e)}
                  />
                }
                label="Remove unmatched patron"
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => closeSyncDialog()}
            disabled={is_load}
          >
            Close
          </Button>
          <Button color="primary" onClick={() => goSync()} disabled={is_load}>
            Confirm
          </Button>
        </DialogActions>
        {is_load === true && <LinearProgress />}
      </Dialog>
    </div>
  );
}
