import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Icon from "@material-ui/core/Icon";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

export default class PageFooter extends Component {
  render() {
    const styles = {
      footerBullet: {
        color: "#8d97ad",
        fontSize: ".9rem",
      },
      footerBulletIcon: {
        color: "#8d97ad",
        paddingBottom: "5px",
      },
      footerBulletText: {
        color: "#8d97ad",
        paddingBottom: "5px",
      },
    };
    return (
      <>
        <div style={{ background: "#343a40", paddingBottom: "20px" }}>
          <Container maxWidth="md">
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <h3 style={{ color: "#fff" }}>ติดต่อโรงเรียน</h3>
                <Grid container style={styles.footerBullet}>
                  <Grid item xs={1} style={styles.footerBulletIcon}>
                    <Icon style={{ fontSize: "1.3rem" }}>home</Icon>
                  </Grid>
                  <Grid item xs={11} style={styles.footerBulletText}>
                    292 หมู่ 6 กม.ที่ 4 ถ.เชียงใหม่-แม่โจ้ ตำบลหนองจ๊อม
                    อำเภอสันทราย จังหวัดเชียงใหม่ 50210
                  </Grid>
                  <Grid item xs={1} style={styles.footerBulletIcon}>
                    <Icon style={{ fontSize: "1.3rem" }}>phone_iphone</Icon>
                  </Grid>
                  <Grid item xs={11} style={styles.footerBulletText}>
                    089-7007798, 0-5334-5912
                  </Grid>
                  <Grid item xs={1} style={styles.footerBulletIcon}>
                    <Icon style={{ fontSize: "1.3rem" }}>email</Icon>
                  </Grid>
                  <Grid item xs={11} style={styles.footerBulletText}>
                    info@tonkla.ac.th
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
          </Container>
        </div>
        <div style={{ background: "#263238", padding: "10px 15px" }}>
          <div style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              size="small"
              color="inherit"
              style={{ color: "#fff" }}
              onClick={() =>
                (window.location = process.env.PUBLIC_URL + "/login")
              }
              startIcon={
                <VpnKeyIcon color="inherit" style={{ color: "#fff" }} />
              }
            >
              Staff Sign In
            </Button>
          </div>
        </div>
      </>
    );
  }
}
