import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import Hidden from "@material-ui/core/Hidden";

import SearchDialog from "./components/SearchDialog";
import Conf from "./Conf";

export default class Biblio extends Component {
  state = {};
  componentDidMount() {
    this.decodeQuery();
  }
  updateQuery(query) {
    this.setState({ query });
  }
  async decodeQuery() {
    var query = [];
    try {
      const decodedData = window.atob(this.props.match.params.query);
      query = JSON.parse(decodeURIComponent(decodedData));
      query = query.query;
    } catch {
      query = [
        {
          field: "All",
          value: "",
        },
      ];
    }

    var biblio = await this.getBiblio();
    console.log(biblio);
    this.setState({ query, biblio });
  }
  getBiblio() {
    return new Promise(async (resolve) => {
      if (this.props.match.params.id) {
        axios
          .get(Conf.backendURL + "biblio/" + this.props.match.params.id)
          .then((res) => {
            resolve(res.data);
          });
      } else resolve(null);
    });
  }
  addQuery() {
    var { query } = this.state;
    var len = query.length - 1;
    query[len]["condition"] = "$and";
    query.push({
      field: "All",
      value: "",
    });
    this.setState({ query });
  }
  removeQuery() {
    var { query } = this.state;
    var len = query.length - 1;
    if (len > 0) {
      query.splice(len, 1);
      delete query[len - 1].condition;
      this.setState({ query });
    }
  }
  goSearch() {
    var query = JSON.parse(JSON.stringify(this.state.query));
    query = query.filter((j) => j.value.trim() !== "");

    var params = {
      query: query,
      skip: 0,
    };
    const encodedData = window.btoa(encodeURIComponent(JSON.stringify(params)));
    window.location = process.env.PUBLIC_URL + "/opac/search/" + encodedData;
  }
  render() {
    const { query, biblio } = this.state;
    return (
      <div>
        <div
          style={{
            borderTop: "4px solid #4db6ac",
            borderBottom: "1px solid #ddd",
          }}
        >
          <div style={{ textAlign: "right" }}>
            <IconButton
              color="primary"
              component="span"
              onClick={() =>
                (window.location = process.env.PUBLIC_URL + "/login")
              }
            >
              <VpnKeyIcon color="primary" />
            </IconButton>
          </div>
          <Container maxWidth="md">
            <Grid
              container
              style={{ padding: "0px 0px", paddingBottom: "0px" }}
              spacing={1}
            >
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    this.props.history.push(process.env.PUBLIC_URL + "/")
                  }
                >
                  Back to Home
                </Button>
              </Grid>
            </Grid>
            <div>
              <SearchDialog
                query={query}
                onQueryUpdate={(query) => this.updateQuery(query)}
                onAddQueryStack={() => this.addQuery()}
                onRemoveQueryStack={() => this.removeQuery()}
                goSearch={() => this.goSearch()}
              />
            </div>
          </Container>
        </div>
        {biblio && (
          <Container maxWidth="lg">
            <ShowBiblio biblio={biblio} />
            {biblio.items && <ShowItems items={biblio.items} />}
          </Container>
        )}
      </div>
    );
  }
}

class ShowBiblio extends Component {
  render() {
    const { biblio } = this.props;
    const styles = {
      bibLabel: {
        textAlign: "right",
        padding: "10px 10px",
        verticalAlign: "top",
        background: "#e0f2f1",
        borderBottom: "1px solid #fff",
        fontWeight: 400,
        color: "#333",
      },
      bibValue: {
        padding: "10px 10px",
        verticalAlign: "top",
        borderBottom: "1px solid #e1e1e1",
        color: "#333",
      },
    };
    return (
      <div style={{ paddingTop: "30px" }}>
        <Grid container>
          {Object.keys(biblio.metadatas).map((field) => (
            <React.Fragment key={field}>
              <Hidden xsDown>
                <Grid item xs={12} sm={5} md={5} lg={3} style={styles.bibLabel}>
                  {field.toUpperCase()} :
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid
                  item
                  xs={12}
                  style={{ paddingTop: "10px", fontWeight: 400 }}
                >
                  {field.toUpperCase()} :
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={7} md={7} lg={9} style={styles.bibValue}>
                {biblio.metadatas[field].map((val, key) => (
                  <div key={key}>{val}</div>
                ))}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        {/* {biblio.metadatas.map((data) => (
          <>
            <Grid container>
              <Grid item xs={12} md={6} lg={4}>
                asdfasdf
              </Grid>
              <Grid item xs={12} md={6} lg={8}>
                asdfasdf
              </Grid>
            </Grid>
          </>
        ))} */}
      </div>
    );
  }
}

class ShowItems extends Component {
  state = {};
  async componentDidMount() {
    var locations = await this.getLocation();
    this.setState({ locations });
  }
  getLocation() {
    return new Promise((resolve) => {
      axios.get(Conf.backendURL + "location").then((res) => {
        resolve(res.data);
      });
    });
  }
  getStatus(item) {
    console.log(item);
    if (!item.checkout || item.checkout === null) return "Available";
    else if (item.checkout && item.checkout.due_date)
      return "Due : " + item.checkout.due_date;
    else return "";
  }
  getLocationName(id) {
    var { locations } = this.state;
    if (locations) {
      var i = locations.findIndex((j) => j._id === id);
      if (i > -1) return locations[i].name;
      else return "";
    }
  }
  render() {
    const { items } = this.props;
    const styles = {
      itemHeader: {
        textAlign: "center",
        color: "#fff",
        background: "#26a69a",
      },
    };
    return (
      <div style={{ margin: "50px auto 50px" }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={styles.itemHeader}>Location</TableCell>
                <TableCell style={styles.itemHeader}>Call No.</TableCell>
                <TableCell style={styles.itemHeader}>Barcode</TableCell>
                <TableCell style={styles.itemHeader}>Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {items &&
                items.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell style={{ textAlign: "center" }}>
                      {item.location.name}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {item.call_no}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {item.barcode}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {this.getStatus(item)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
