import React from "react";

import SearchMainPage from "./components/SearchMainPage";
import SearchPage from "./components/SearchPage";

class OPAC extends React.Component {
  state = { query: this.props.match.params.query || null };
  componentDidMount() {
    // if (this.props.match.params) {
    //   try {
    //     let query = window.atob(this.props.match.params.query);
    //     this.setState({ query });
    //   } catch (e) {
    //     this.setState({ query: null });
    //   }
    // }
  }
  render() {
    const { query } = this.state;
    return (
      <div>
        {query && query.length > 0 ? (
          <SearchPage {...this.props} />
        ) : (
          <SearchMainPage {...this.props} />
        )}
      </div>
    );
  }
}

export default OPAC;
