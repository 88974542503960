import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import SearchIcon from "@material-ui/icons/Search";

import ShowSearch from "./ShowSearch";
import SearchDialog from "./SearchDialog";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Conf from "../Conf";
import axios from "axios";
import { search_key } from "../js/main";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

export default class SearchPage extends Component {
  state = {
    is_load: true,
  };
  async componentDidMount() {
    var query = {};
    var request = {};
    try {
      const decodedData = window.atob(this.props.match.params.query);
      query = JSON.parse(decodeURIComponent(decodedData));
      // request.query = await this.createQuery(query.query);
      request.query = await this.encodeQuery(query.query);
      request.skip = JSON.parse(JSON.stringify(query.skip));
    } catch {
      query = {};
      request.skip = 0;
    }
    if (!query.query || query.query.length === 0) {
      query = { query: [] };
      query.query.push({ field: "All", value: "" });
    }
    this.setState(
      {
        query: query.query,
        is_loading: true,
      },
      () => {
        axios.post(Conf.backendURL + "biblio/search", request).then((res) => {
          this.setState({
            request: request,
            searchResult: res.data,
            is_loading: false,
          });
        });
      }
    );
  }
  getFieldCondition(q) {
    return new Promise(async (resolve) => {
      var i = await search_key.findIndex((j) => j.label === q.field);
      var obj = { $or: [] };
      for (let field of search_key[i].fields) {
        let field_name = "metadatas." + field;
        let field_obj = {};
        field_obj[field_name] = { $regex: ".*" + q.value + ".*" };
        obj["$or"].push(field_obj);
      }
      resolve(obj);
    });
  }
  encodeQuery(query) {
    return new Promise(async (resolve) => {
      var new_query = {};
      var condition = "$and";
      new_query[condition] = [];
      var i = 0;
      for (let q of query) {
        let obj = await this.getFieldCondition(q);
        if (i === 0) {
          new_query[condition].push(obj);
        } else if (condition === query[i - 1].condition) {
          new_query[condition].push(obj);
        } else {
          let arr = JSON.parse(JSON.stringify(new_query));
          new_query = {};
          condition = query[i - 1].condition;
          new_query[condition] = [];
          new_query[condition].push(arr);
          new_query[condition].push(obj);
        }
        i++;
      }
      resolve(new_query);
    });
  }

  createQuery(query) {
    return new Promise(async (resolve) => {
      var new_query = [];
      for (let q of query) {
        //await query.map(async (q) => {
        var i = await search_key.findIndex((j) => j.label === q.field);
        var obj = { $or: [] };
        for (let field of search_key[i].fields) {
          let field_obj = {
            name: field,
            value: { $regex: ".*" + q.value + ".*" },
          };
          obj["$or"].push(field_obj);
        }
        new_query.push(obj);
      }
      resolve({ $and: new_query });
    });
  }
  updateQuery(query) {
    this.setState({ query });
  }
  addQuery() {
    var { query } = this.state;
    var len = query.length - 1;
    query[len]["condition"] = "$and";
    query.push({
      field: "All",
      value: "",
    });
    this.setState({ query });
  }
  removeQuery() {
    var { query } = this.state;
    var len = query.length - 1;
    if (len > 0) {
      query.splice(len, 1);

      delete query[len - 1].condition;
      this.setState({ query });
    }
  }
  goSearch() {
    var query = JSON.parse(JSON.stringify(this.state.query));
    query = query.filter((j) => j.value.trim() !== "");

    var params = {
      query: query,
      skip: 0,
    };
    const encodedData = window.btoa(encodeURIComponent(JSON.stringify(params)));
    // const decodedData = window.atob(encodedData);
    window.location = process.env.PUBLIC_URL + "/opac/search/" + encodedData;
  }
  searchChangePage(start) {
    var { request } = this.state;
    request.start = start;
    request.skip = start;

    var query = JSON.parse(JSON.stringify(this.state.query));
    query = query.filter((j) => j.value.trim() !== "");

    var params = {
      query: query,
      skip: start,
    };
    const encodedData = window.btoa(encodeURIComponent(JSON.stringify(params)));
    // window.location = process.env.PUBLIC_URL + "/opac/search/" + encodedData;
    this.props.history.push(
      process.env.PUBLIC_URL + "/opac/search/" + encodedData
    );
    this.getSearchResult(params);
  }
  async getSearchResult(request) {
    request.query = await this.encodeQuery(request.query);
    axios.post(Conf.backendURL + "biblio/search", request).then((res) => {
      this.setState({
        request: request,
        searchResult: res.data,
        is_loading: false,
      });
    });
  }
  searchGoBib(id) {
    if (id)
      this.props.history.push(
        process.env.PUBLIC_URL +
          "/opac/biblio/" +
          id +
          "/" +
          this.props.match.params.query
      );
  }
  render() {
    const { searchResult, is_loading, query, request } = this.state;
    return (
      <>
        <div
          style={{
            borderTop: "4px solid #4db6ac",
            borderBottom: "1px solid #ddd",
          }}
        >
          <div style={{ textAlign: "right" }}>
            <IconButton
              color="primary"
              component="span"
              onClick={() =>
                (window.location = process.env.PUBLIC_URL + "/login")
              }
            >
              <VpnKeyIcon color="primary" />
            </IconButton>
          </div>
          <Container maxWidth="md">
            {/* <Paper> */}
            <Grid
              container
              style={{ padding: "0px 0px", paddingBottom: "0px" }}
              spacing={1}
            >
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    this.props.history.push(process.env.PUBLIC_URL + "/")
                  }
                >
                  Back to Home
                </Button>
              </Grid>
            </Grid>
            <div>
              <SearchDialog
                query={query}
                onQueryUpdate={(query) => this.updateQuery(query)}
                onAddQueryStack={() => this.addQuery()}
                onRemoveQueryStack={() => this.removeQuery()}
                goSearch={() => this.goSearch()}
              />
            </div>

            {/* </Paper> */}
          </Container>
          <div style={{ textAlign: "center", color: "#333", height: "25px" }}>
            {searchResult && <span>{searchResult.count} result(s) found</span>}
          </div>
        </div>

        {is_loading === true && (
          <div>
            <LinearProgress />
          </div>
        )}
        <div>
          {searchResult && (
            <>
              <ShowSearch
                searchResult={searchResult}
                // url={`${process.env.PUBLIC_URL}/catalog`}
                onSelect={(id) => this.searchGoBib(id)}
                skip={request.skip}
                onChangePage={(page) => this.searchChangePage(page)}
                // onSelectItem={(item, selected) =>
                //   this.onSelectItem(item._id, selected)
                // }
                // selectItems={selectItems}
              />
            </>
          )}
        </div>
      </>
    );
  }
}
/*
class SearchForm extends Component {
  updateForm(e, field, key) {
    var { query } = this.props;
    query[key][field] = e.target.value;
    this.props.onQueryUpdate(query);
  }
  render() {
    const { query } = this.props;
    return (
      <div>
        <Grid container style={{ padding: "30px 0px" }} spacing={1}>
          {query &&
            query.map((q, key) => (
              <React.Fragment key={key}>
                <Grid item xs={4} md={3} lg={2}>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      native
                      fullWidth
                      value={q.field}
                      onChange={(e) => this.updateForm(e, "field", key)}
                      //   label="Age"
                      inputProps={{
                        style: {
                          background: "#fff",
                          paddingTop: "11px",
                          paddingBottom: "10px",
                        },
                      }}
                      size="small"
                    >
                      {search_key.map((field) => (
                        <option value={field.label} key={field.label}>
                          {field.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={7} lg={8}>
                  <TextField
                    variant="outlined"
                    placeholder="Keyword..."
                    value={q.value}
                    onChange={(e) => this.updateForm(e, "value", key)}
                    fullWidth
                    size="small"
                    InputProps={{
                      style: {
                        background: "#fff",
                      },
                    }}
                    // onKeyUp={(e) => this.goSearch(e)}
                  />
                </Grid>
                {key < query.length - 1 ? (
                  <Grid item xs={2}>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        native
                        fullWidth
                        value={q.condition}
                        onChange={(e) => this.updateForm(e, "condition", key)}
                        inputProps={{
                          style: {
                            background: "#fff",
                            paddingTop: "11px",
                            paddingBottom: "10px",
                          },
                        }}
                        size="small"
                      >
                        <option value="$and">AND</option>
                        <option value="$or">OR</option>
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    <IconButton
                      color="primary"
                      aria-label="add to shopping cart"
                      style={{ padding: "2px 2px", marginRight: "5px" }}
                      onClick={() => this.props.onAddQueryStack()}
                    >
                      <Icon style={{ fontSize: "2.4rem" }}>add_circle</Icon>
                    </IconButton>
                    <IconButton
                      color="secondary"
                      aria-label="add to shopping cart"
                      style={{ padding: "2px 2px" }}
                      disabled={query.length === 1}
                      onClick={() => this.props.onRemoveQueryStack()}
                    >
                      <Icon style={{ fontSize: "2.4rem" }}>remove_circle</Icon>
                    </IconButton>
                  </Grid>
                )}
              </React.Fragment>
            ))}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<Icon>search</Icon>}
              onClick={() => this.props.goSearch()}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
*/
