import React, { Component } from "react";
import { getProfile } from "./js/main";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import axios from "axios";
import Conf from "./Conf";

const pageName = "bookcart";
export default class BookCart extends Component {
  state = {
    profile: {},
    selectItems: [],
  };
  componentDidMount() {
    this.checkAuth();
  }
  async checkAuth() {
    var profile = await getProfile();
    if (
      profile.patron &&
      profile.patron.permission &&
      profile.patron.permission.indexOf(pageName) > -1
    ) {
      var selectItems = await this.getItems();
      console.log(selectItems.items);
      this.setState({ selectItems: selectItems.items });
    } else window.location = process.env.PUBLIC_URL + "/login";
  }
  getElementValue(item, field) {
    var val = item.biblio_id.elements.filter((el) => el.name === field);
    if (val) {
      return item.biblio_id.elements[0].value;
    } else return null;
    // return val;
  }
  getItems() {
    return new Promise((resolve) => {
      var cookie = localStorage.getItem(Conf.cookieName);
      cookie = JSON.parse(cookie);
      /*
          0: "5fba12ca397b221254b8617e"
          1: "5fba12ca397b221254b86181"      
      */
      axios
        .post(
          Conf.backendURL + "item",
          {
            query: { _id: { $in: cookie.itemCart } },
          },
          {
            headers: { Authorization: cookie.token },
          }
        )
        .then((res) => {
          resolve(res.data);
        });
    });
  }
  async removeItem(id) {
    var { selectItems } = this.state;
    var cookie = localStorage.getItem(Conf.cookieName);
    cookie = JSON.parse(cookie);
    selectItems = selectItems.filter((j) => j._id !== id);
    var ids = await selectItems.map((i) => i._id);
    cookie.itemCart = ids;
    this.setState({ selectItems }, () =>
      localStorage.setItem(Conf.cookieName, JSON.stringify(cookie))
    );
  }
  print() {
    // window.open(`${process.env.PUBLIC_URL}/report/barcode`);
    document.getElementById("printForm").submit();
  }
  render() {
    var { profile, selectItems } = this.state;
    return (
      <React.Fragment>
        {profile && (
          <React.Fragment>
            <main className="content">
              <Grid container>
                <Grid item xl={12} md={6}>
                  <h1 className="pageHeader">
                    <ShoppingCartIcon
                      className="pageHeaderIcon"
                      fontSize="large"
                    />
                    Book Cart
                  </h1>
                </Grid>
                <Grid
                  item
                  xl={12}
                  md={6}
                  style={{ textAlign: "right", paddingTop: "15px" }}
                >
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={() => this.print()}
                  >
                    <PrintIcon style={{ fontSize: "2em" }} />
                  </IconButton>
                </Grid>
              </Grid>

              <List component="nav">
                {selectItems.map((item) => (
                  <div key={item._id}>
                    <ListItem /* button */>
                      <ListItemIcon>
                        <IconButton
                          onClick={() => this.removeItem(item._id)}
                          color="inherit"
                          style={{ color: "#e66", marginRight: "10px" }}
                          component="span"
                        >
                          <RemoveCircleIcon style={{ fontSize: "1.5em" }} />
                        </IconButton>
                      </ListItemIcon>
                      <ListItemText>
                        <div style={{ fontWeight: "400" }}>
                          {this.getElementValue(item, "title")}
                        </div>
                        <div style={{ fontSize: ".7em" }}>{item.call_no}</div>
                        <div style={{ fontSize: ".7em" }}>{item.barcode}</div>
                      </ListItemText>
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
              <iframe
                title="printFrame"
                id="printFrame"
                name="printFrame"
                style={{ width: "0px", height: "0px", border: "none" }}
              ></iframe>
              <form
                action={`${Conf.frontendURL}report/barcode`}
                // action="http://localhost:3000/report/barcode"
                id="printForm"
                target="printFrame"
                method="get"
              ></form>
            </main>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
