import React, { Component } from 'react'
import { getProfile } from './js/main';
import TextField from '@material-ui/core/TextField';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LoopIcon from '@material-ui/icons/Loop';
import DoneIcon from '@material-ui/icons/Done';
import Checkbox from '@material-ui/core/Checkbox';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


import Conf from './Conf';
import axios from 'axios';

export default class Patron extends Component {
    pageName = "patron";
    state = {
        cookie: JSON.parse(localStorage.getItem(Conf.cookieName)),
        locations: [],
        form: {
            name: "",
            is_default: false
        },
        openExchange: false
    }
    componentDidMount() {
        this.checkAuth();
    }
    async checkAuth() {
        var profile = await getProfile();
        if (profile.patron && profile.patron.permission && profile.patron.permission.indexOf(this.pageName) > -1) {
            var locations = await this.getLocation();
            this.setState({ locations });
        } else window.location = process.env.PUBLIC_URL + "/login";
    }
    getLocation() {
        return new Promise(resolve => {
            axios.get(Conf.backendURL + 'location').then(res => {
                resolve(res.data);
            });
        })
    }
    save() {
        var { form, cookie } = this.state;
        axios.put(Conf.backendURL + 'location', form, { headers: { Authorization: cookie.token } }).then(async res => {
            if (res.data.error) alert(res.data.error);
            else {

                var locations = await this.getLocation();
                form = {
                    name: "",
                    is_default: false
                }
                this.setState({ locations, form });
            }
        });
    }
    edit(id, callback) {
        var { form, locations } = this.state;
        var i = locations.findIndex(j => j._id === id);
        if (i > -1) {
            form = JSON.parse(JSON.stringify(locations[i]));
            this.setState({ form }, () => {
                if (callback) callback();
            });
        }
    }
    clearSelect() {
        var form = {
            name: "",
            is_default: false
        }
        this.setState({ form });
    }
    changeForm(e) {
        var id = e.target.id;
        var { form } = this.state;
        if (id === "is_default") form[id] = e.target.checked;
        else form[id] = e.target.value;
        this.setState({ form });
    }
    delete(id) {
        if (window.confirm("Confirm to delete")) {
            var { cookie } = this.state;
            axios.delete(Conf.backendURL + 'location/' + id, { headers: { Authorization: cookie.token } }).then(async res => {
                if (res.data.error) alert(res.data.error);
                else {
                    var locations = await this.getLocation();
                    var form = {
                        name: "",
                        is_default: false
                    }
                    this.setState({ locations, form });
                }
            });
        }
    }
    closeExchange() {
        var { openExchange } = this.state;
        openExchange = !openExchange;
        this.setState({ openExchange }, () => {
            if (openExchange === false) this.clearSelect()
        });
    }
    render() {
        const { form, locations, openExchange } = this.state;
        return <React.Fragment>
            <main className="content" >
                <MoveDialog is_open={openExchange} onClose={() => this.closeExchange()} location={form} locations={locations} />
                <h1 className="pageHeader">
                    <LocationOnIcon className="pageHeaderIcon" fontSize="large" />
                    Location
                </h1>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '80px' }}>No</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell style={{ width: '80px' }}>Default</TableCell>
                                    <TableCell style={{ width: '150px' }}>Control</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    locations && locations.map((location, key) => <TableRow key={key} style={location._id === form._id ? { background: '#efe' } : { background: '#fff' }}>
                                        <TableCell align="center" style={{ padding: '3px 3px' }}>
                                            {
                                                location._id === form._id ? <IconButton color="secondary" aria-label="Change Location" onClick={() => this.delete(location._id)}>
                                                    <HighlightOffIcon />
                                                </IconButton>
                                                    : <React.Fragment>{key + 1}</React.Fragment>
                                            }
                                        </TableCell>
                                        <TableCell>{location.name}</TableCell>
                                        <TableCell align="center" style={{ padding: '3px 3px' }}>{location.is_default === true && <DoneIcon />}</TableCell>
                                        <TableCell align="center" style={{ padding: '3px 3px' }}>

                                            {
                                                location._id === form._id ? <IconButton aria-label="Edit" onClick={() => this.clearSelect()}>
                                                    <UndoIcon style={{ color: '#00a152' }} />
                                                </IconButton>
                                                    : <IconButton color="primary" aria-label="Edit" onClick={() => this.edit(location._id)}>
                                                        <EditIcon />
                                                    </IconButton>
                                            }
                                            <IconButton aria-label="Change Location" onClick={() => this.edit(location._id, this.closeExchange())}>
                                                <LoopIcon style={{ color: '#ff6333' }} />
                                            </IconButton>


                                        </TableCell>

                                    </TableRow>)
                                }
                                <TableRow>
                                    <TableCell align="center" style={{ padding: '5px 5px' }}>
                                        <AddCircleIcon fontSize="large" style={{ color: '#aaa' }} />
                                    </TableCell>
                                    <TableCell align="center" style={{ padding: '2px 2px' }}>
                                        <TextField id="name" placeholder="Location Name" variant="outlined"
                                            fullWidth
                                            onChange={(e) => this.changeForm(e)}
                                            value={form.name}
                                        />
                                    </TableCell>

                                    <TableCell align="center" style={{ padding: '2px 2px' }}>
                                        <Checkbox
                                            id="is_default"
                                            checked={form.is_default}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'Set Default' }}
                                            onChange={(e) => this.changeForm(e)}
                                        />
                                    </TableCell>
                                    <TableCell align="center" style={{ padding: '2px 2px' }}>
                                        <IconButton color="primary" aria-label="Save" onClick={() => this.save()}>
                                            <SaveIcon fontSize="large" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </main>

        </React.Fragment >
    }
}


class MoveDialog extends React.Component {
    moveLocation() {
        var form = {
            target_id: document.getElementById("target_id").value,
            source_id: this.props.location._id
        }
        console.log(form);
        var cookie = JSON.parse(localStorage.getItem(Conf.cookieName));
        axios.put(Conf.backendURL + 'location/move', form, { headers: { Authorization: cookie.token } }).then(res => {
            if (res.data.error) alert(res.data.error);
            this.props.onClose()
        });
    }
    render() {
        const { location, is_open, locations } = this.props;
        return (
            <div style={{ width: '300px' }}>
                <Dialog
                    fullWidth={true}
                    open={is_open}
                    // onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Move Items</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>Move all items from</div>
                            <h2 style={{ fontWeight: '400', color: '#111', padding: '0px 0px', marginTop: '10px' }}>{location.name}</h2>

                            <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-age-native-simple">to Location</InputLabel>
                                <Select
                                    native
                                    // value={state.age}
                                    // onChange={handleChange}
                                    label="to Location"
                                    inputProps={{
                                        name: 'target_id',
                                        id: "target_id"
                                    }}
                                >
                                    {locations.filter(j => j._id !== location._id).map(l => <option key={l._id} value={l._id}>{l.name}</option>)}
                                </Select>
                            </FormControl>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.moveLocation()} color="primary">
                            Move
                        </Button>
                        <Button onClick={() => this.props.onClose()} color="primary" autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        )
    }
}
