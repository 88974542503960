import React, { Component } from "react";
import ImportPatronDialog from "./components/ImportPatronDialog";
import PeopleIcon from "@material-ui/icons/People";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import SaveIcon from "@material-ui/icons/Save";
import UndoIcon from "@material-ui/icons/Undo";
import EditIcon from "@material-ui/icons/Edit";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import ClearIcon from "@material-ui/icons/Clear";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";

import { getProfile } from "./js/main";
import Conf from "./Conf";
import axios from "axios";

export default class Patron extends Component {
  pageName = "patron";
  state = {
    cookie: JSON.parse(localStorage.getItem(Conf.cookieName)),
    open_sync_dialog: false,
    select_type_id: null,
    // patron: {},
    tabNo: 1,
    keyword: "",
    searchResult: [],
  };
  componentDidMount() {
    this.checkAuth();
  }
  // componentDidUpdate() {
  //     if (this.props.match.params && this.props.match.params.id && this.state.patron._id !== this.props.match.params.id) {
  //         console.log(this.state.patron._id + " !== " + this.props.match.params.id)
  //         this.getPatron();
  //     }
  // }
  changeTab(e, val) {
    this.setState({ tabNo: val });
  }
  async goSearch(e) {
    if (e.key === "Enter") {
      var keyword = e.target.value.trim();
      var searchResult = await this.getSearchResult(keyword);
      this.setState({ tabNo: 0, keyword: keyword, searchResult: searchResult });
    }
  }
  getSearchResult(keyword) {
    return new Promise((resolve) => {
      var { cookie } = this.state;
      axios
        .post(
          Conf.backendURL + "patron/",
          { keyword: keyword },
          { headers: { Authorization: cookie.token } }
        )
        .then((res) => {
          resolve(res.data);
        });
    });
  }
  goPatron(id) {
    // this.setState({ keyword: '' });
    window.location = process.env.PUBLIC_URL + "/patron/" + id;
  }
  async checkAuth() {
    var profile = await getProfile();
    if (
      profile.patron &&
      profile.patron.permission &&
      profile.patron.permission.indexOf(this.pageName) > -1
    ) {
      if (this.props.match && this.props.match.params.id) {
        this.getPatron();
      } else {
        this.setState({ patron: {} });
      }
    } else window.location = process.env.PUBLIC_URL + "/login";
  }
  getPatron() {
    var { cookie } = this.state;
    axios
      .get(Conf.backendURL + "patron/" + this.props.match.params.id, {
        headers: { Authorization: cookie.token },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({ patron: res.data });
      });
  }
  openSyncDialog(id) {
    var { open_sync_dialog } = this.state;
    this.setState({ open_sync_dialog: !open_sync_dialog, select_type_id: id });
  }
  render() {
    const {
      tabNo,
      keyword,
      searchResult,
      patron,
      open_sync_dialog,
      select_type_id,
    } = this.state;
    return (
      <React.Fragment>
        <main className="content">
          <ImportPatronDialog
            is_open={open_sync_dialog}
            type_id={select_type_id}
            closeSyncDialog={() => this.openSyncDialog()}
          />
          <h1
            className="pageHeader"
            style={{ margin: "0px", paddingBottom: "0px" }}
          >
            <Grid container>
              <Grid item xs={12} md={7} lg={8}>
                <h1 className="pageHeader" style={{ fontSize: "1em" }}>
                  <PeopleIcon className="pageHeaderIcon" fontSize="large" />
                  Patrons
                </h1>
              </Grid>
              <Grid item xs={12} md={5} lg={4} style={{ paddingTop: "20px" }}>
                <TextField
                  id="keyword"
                  label="Search"
                  variant="outlined"
                  fullWidth
                  onKeyUp={(e) => this.goSearch(e)}
                  // onChange={(e) => this.changeKeyword(e)}
                  // value={keyword}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </h1>
          <Paper square style={{ paddingBottom: "20px" }}>
            <Tabs
              value={tabNo}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, val) => this.changeTab(e, val)}
              style={{ marginBottom: "20px", padding: "10px 10px" }}
            >
              <Tab label="Patron Information" />
              <Tab label="Patron Type" />
            </Tabs>
            {keyword.trim() !== "" ? (
              <PatronSearch
                result={searchResult}
                goPatron={(id) => this.goPatron(id)}
              />
            ) : (
              <>
                {tabNo === 0 ? (
                  <>{patron && <PatronForm patron={patron} />}</>
                ) : (
                  <TypeForm openSyncDialog={(id) => this.openSyncDialog(id)} />
                )}
              </>
            )}
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}

class PatronSearch extends Component {
  render() {
    var { result } = this.props;
    return (
      <>
        <div style={{ fontWeight: "400", padding: "10px 10px" }}>
          Result : {result.count} record(s)
        </div>

        <Divider />
        <List component="nav" style={{ padding: "0px 0px" }}>
          {result.result.map((r, key) => (
            <React.Fragment key={key}>
              <ListItem
                component={Link}
                to=""
                onClick={() => this.props.goPatron(r._id)}
              >
                <ListItemIcon style={{ width: "80px" }}>
                  <PeopleIcon style={{ fontSize: "4em" }} />
                </ListItemIcon>
                <ListItemText>
                  <div
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "400",
                      color: "#333",
                    }}
                  >
                    {r.first_name} {r.last_name}
                  </div>
                  <div style={{ color: "#666" }}>{r.patron_code}</div>
                </ListItemText>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </>
    );
  }
}
class PatronForm extends Component {
  state = {
    types: [],
    cookie: JSON.parse(localStorage.getItem(Conf.cookieName)),
    patron: {
      type: 0,
    },
  };
  async componentDidMount() {
    var types = await this.getTypes();
    var patron = { type: 0 };
    if (this.props.patron._id) {
      patron = this.props.patron;
      this.setForm();
    }

    this.setState({ patron, types });
  }
  getTypes() {
    return new Promise((resolve) => {
      axios.get(Conf.backendURL + "patron/type").then((res) => {
        // this.setState({ types: res.data });
        resolve(res.data);
      });
    });
  }
  changeType(e) {
    var { patron } = this.state;
    patron.type = e.target.value;
    this.setState({ patron });
  }
  setForm() {
    var { patron } = this.props;
    document.getElementById("prefix").value = patron.prefix;
    document.getElementById("first_name").value = patron.first_name;
    document.getElementById("last_name").value = patron.last_name;
    document.getElementById("address").value = patron.address;
    document.getElementById("email").value = patron.email;
    document.getElementById("phone_no").value = patron.phone_no;
    document.getElementById("usn").value = patron.usn;
    document.getElementById("patron_code").value = patron.patron_code;
    document.getElementById("note").value = patron.note;
  }
  getForm() {
    return new Promise((resolve) => {
      var r = {
        prefix: document.getElementById("prefix").value.trim(),
        first_name: document.getElementById("first_name").value.trim(),
        last_name: document.getElementById("last_name").value.trim(),
        address: document.getElementById("address").value.trim(),
        email: document.getElementById("email").value.trim(),
        phone_no: document.getElementById("phone_no").value.trim(),
        usn: document.getElementById("usn").value.trim(),
        patron_code: document.getElementById("patron_code").value.trim(),
        note: document.getElementById("note").value.trim(),
        is_admin: document.getElementById("is_admin").checked,
        type: this.state.patron.type,
      };
      if (r.type === 0) delete r.type;
      resolve(r);
    });
  }
  delete() {
    if (window.confirm("Confirm to delete")) {
      var { cookie, patron } = this.state;
      axios
        .delete(Conf.backendURL + "patron/" + patron._id, {
          headers: { Authorization: cookie.token },
        })
        .then((res) => {
          console.log(res);
          window.location = process.env.PUBLIC_URL + "/patron";
        });
    }
  }
  async save() {
    var form = await this.getForm();
    var { cookie, patron } = this.state;
    if (patron._id) form._id = patron._id;
    axios
      .put(Conf.backendURL + "patron/", form, {
        headers: { Authorization: cookie.token },
      })
      .then((res) => {
        console.log(res);
        if (res.data.signin_timeout === true)
          window.location = process.env.PUBLIC_URL + "/login";
        else if (res.data.error) alert(res.data.error);
        else {
          alert("Save completed");
        }
      });
  }
  changeAdminStatus(e) {
    var { patron } = this.state;
    patron.is_admin = !patron.is_admin;
    this.setState({ patron });
  }
  render() {
    const { patron, types } = this.state;
    return (
      <div style={{ padding: "0px 10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={2}>
            <TextField
              required
              id="prefix"
              label="Prefix"
              fullWidth
              placeholder="คำนำหน้า"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={5}>
            <TextField
              required
              id="first_name"
              label="First Name"
              fullWidth
              placeholder="ชื่อ"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={5}>
            <TextField
              required
              id="last_name"
              label="Last Name"
              fullWidth
              placeholder="นามสกุล"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address"
              label="Address"
              fullWidth
              placeholder="ที่อยู่"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              required
              id="patron_code"
              label="Patron Code"
              fullWidth
              placeholder="บาร์โค้ด"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="email"
              label="e-Mail"
              fullWidth
              placeholder="อีเมล"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="phone_no"
              label="Phone Number"
              fullWidth
              placeholder="โทรศัพท์"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="patron_type_label" shrink>
                Type
              </InputLabel>
              <Select
                labelId="patron_type_label"
                id="patron_type"
                value={patron.type}
                onChange={(e) => this.changeType(e)}
                label="Type"
              >
                <MenuItem value={0} disabled>
                  <div>Select Type</div>
                </MenuItem>
                {types.map((type) => (
                  <MenuItem value={type._id} key={type._id}>
                    <div>{type.name}</div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TextField
              id="usn"
              label="Username"
              fullWidth
              placeholder="ชื่อผู้ใช้งาน"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={9}>
            <TextField
              id="note"
              label="Note"
              fullWidth
              placeholder="โน๊ต"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="is_admin"
                  checked={patron.is_admin === true ? true : false}
                  onChange={(e) => this.changeAdminStatus(e)}
                  name="is_admin"
                  color="primary"
                />
              }
              label="Library Staff"
            />
          </Grid>
          <Grid item xs={5}>
            {patron._id && (
              <Button
                variant="contained"
                color="secondary"
                size="large"
                startIcon={<ClearIcon />}
                onClick={() => this.delete()}
                style={{ marginRight: "10px" }}
              >
                Delete
              </Button>
            )}
          </Grid>
          <Grid item xs={7} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              size="large"
              startIcon={<UndoIcon />}
              component={Link}
              to={`${process.env.PUBLIC_URL}/patron`}
              style={{
                marginRight: "10px",
                background: "#00a152",
                color: "#fff",
              }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<SaveIcon />}
              onClick={() => this.save()}
            >
              Save Patron
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
class TypeForm extends Component {
  state = {
    edit_id: null,
    types: [],
    cookie: JSON.parse(localStorage.getItem(Conf.cookieName)),
  };
  componentDidMount() {
    this.getTypes();
  }
  selectType(edit_id) {
    var { types } = this.state;
    var i = types.findIndex((j) => j._id === edit_id);
    if (i > -1) {
      document.getElementById("name").value = types[i].name;
      document.getElementById("checkout_day").value = types[i].checkout_day;
      document.getElementById("limit_item").value = types[i].limit_item;
      document.getElementById("fines").value = types[i].fines;
    }
    this.setState({ edit_id });
  }
  getTypes() {
    axios.get(Conf.backendURL + "patron/type").then((res) => {
      this.setState({ types: res.data });
    });
  }
  clearSelect() {
    this.clearForm();
    this.setState({ edit_id: null });
  }
  delete() {
    if (window.confirm("Confirm to delete")) {
      var { cookie, edit_id, types } = this.state;
      axios
        .delete(Conf.backendURL + "patron/type/" + edit_id, {
          headers: { Authorization: cookie.token },
        })
        .then((res) => {
          if (res.data.signin_timeout === true)
            window.location = process.env.PUBLIC_URL + "/login";
          else if (res.data.error) alert(res.data.error);
          else {
            types = types.filter((j) => j._id !== edit_id);
            this.setState({ types: types, edit_id: null });
            this.clearForm();
          }
        });
    }
  }
  clearForm() {
    document.getElementById("name").value = "";
    document.getElementById("checkout_day").value = "";
    document.getElementById("limit_item").value = "";
    document.getElementById("fines").value = "";
  }
  save() {
    var { cookie, edit_id } = this.state;
    var form = {
      name: document.getElementById("name").value,
      checkout_day:
        parseInt(document.getElementById("checkout_day").value) || 0,
      limit_item: parseInt(document.getElementById("limit_item").value) || 0,
      fines: parseInt(document.getElementById("fines").value) || 0,
    };
    if (edit_id) form._id = edit_id;
    axios
      .put(Conf.backendURL + "patron/type", form, {
        headers: { Authorization: cookie.token },
      })
      .then((res) => {
        console.log(res);
        if (res.data.signin_timeout === true)
          window.location = process.env.PUBLIC_URL + "/login";
        else if (res.data.error) alert(res.data.error);
        else {
          var { types } = this.state;
          if (!edit_id) {
            types.push(res.data);
          } else {
            var i = types.findIndex((j) => j._id === edit_id);
            if (i > -1) {
              types[i].name = res.data.name;
              types[i].checkout_day = res.data.checkout_day;
              types[i].limit_item = res.data.limit_item;
              types[i].fines = res.data.fines;
            }
            console.log(types);
          }
          this.setState({ types });
          this.clearSelect();
        }
      });
  }
  render() {
    const { types, edit_id } = this.state;
    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "80px" }}>No</TableCell>
                <TableCell>Patron Type Name</TableCell>
                <TableCell style={{ width: "150px" }}>
                  Borrow Permission (days)
                </TableCell>
                <TableCell style={{ width: "150px" }}>
                  Borrow Limit (Item)
                </TableCell>
                <TableCell style={{ width: "150px" }}>
                  Fines (per days)
                </TableCell>
                <TableCell style={{ width: "100px" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {types.map((type, key) => (
                <TableRow
                  key={type._id}
                  style={edit_id === type._id ? { background: "#cfc" } : {}}
                >
                  <TableCell
                    style={{ padding: "0px 0px", textAlign: "center" }}
                  >
                    {edit_id === type._id ? (
                      <IconButton
                        color="primary"
                        aria-label="Remove Patron Type"
                        onClick={() => this.delete()}
                      >
                        <HighlightOffIcon
                          style={{ fontSize: "1.2em", color: "red" }}
                        />
                      </IconButton>
                    ) : (
                      <div>{key + 1}</div>
                    )}
                  </TableCell>
                  <TableCell>
                    <div>{type.name}</div>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <div>{type.checkout_day}</div>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <div>{type.limit_item}</div>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <div>{type.fines || 0}</div>
                  </TableCell>
                  <TableCell
                    style={{ padding: "0px 0px", textAlign: "center" }}
                  >
                    {edit_id === type._id ? (
                      <IconButton
                        color="primary"
                        aria-label="Edit Patron Type"
                        onClick={() => this.clearSelect()}
                      >
                        <UndoIcon style={{ fontSize: "1em", color: "green" }} />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton
                          color="primary"
                          aria-label="Save Patron Type"
                          title="Save Patron Type"
                          onClick={() => this.selectType(type._id)}
                          style={{ fontSize: "1em", padding: "5px 5px" }}
                        >
                          <EditIcon />
                        </IconButton>
                        {Conf.hdsBackend && (
                          <IconButton
                            color="primary"
                            aria-label="Sync with HDS"
                            title="Sync with HDS"
                            onClick={() => this.props.openSyncDialog(type._id)}
                            style={{
                              fontSize: "1em",
                              padding: "5px 5px",
                              color: "#7c7",
                            }}
                          >
                            <SyncAltIcon />
                          </IconButton>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ padding: "5px 5px" }}>
                  <TextField
                    required
                    id="name"
                    size="small"
                    fullWidth
                    placeholder="ชื่อประเภทผู้ใช้งาน"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </TableCell>
                <TableCell style={{ padding: "5px 5px" }}>
                  <TextField
                    id="checkout_day"
                    fullWidth
                    size="small"
                    placeholder="สิทธิการยืม (วัน)"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { textAlign: "center" } }}
                  />
                </TableCell>
                <TableCell style={{ padding: "5px 5px" }}>
                  <TextField
                    id="limit_item"
                    fullWidth
                    size="small"
                    placeholder="จำนวนเล่ม"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { textAlign: "center" } }}
                  />
                </TableCell>
                <TableCell style={{ padding: "5px 5px" }}>
                  <TextField
                    id="fines"
                    fullWidth
                    placeholder="ค่าปรับ/วัน"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { textAlign: "center" } }}
                  />
                </TableCell>
                <TableCell style={{ padding: "0px 0px", textAlign: "center" }}>
                  <IconButton
                    color="primary"
                    aria-label="Save Patron Type"
                    style={{ padding: "2px 2px" }}
                    onClick={() => this.save()}
                  >
                    <SaveIcon style={{ fontSize: "1.5em" }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
