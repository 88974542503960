import React, { Component } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { search_key } from "../js/main";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";

export default class SearchDialog extends Component {
  updateForm(e, field, key) {
    var { query } = this.props;
    query[key][field] = e.target.value;
    this.props.onQueryUpdate(query);
  }
  render() {
    const { query } = this.props;
    // const query = [
    //   { field: "Title", value: "100", condition: "AND" },
    //   // { field: "Author", value: "100", condition: "OR" },
    //   // { field: "Subject", value: "100" },
    //   { field: "All", value: "" },
    // ];
    return (
      <div>
        <Grid container style={{ padding: "30px 0px" }} spacing={1}>
          {query &&
            query.map((q, key) => (
              <React.Fragment key={key}>
                <Grid item xs={4} md={3} lg={2}>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      native
                      fullWidth
                      value={q.field}
                      onChange={(e) => this.updateForm(e, "field", key)}
                      //   label="Age"
                      inputProps={{
                        style: {
                          background: "#fff",
                          paddingTop: "11px",
                          paddingBottom: "10px",
                        },
                      }}
                      size="small"
                    >
                      {search_key.map((field) => (
                        <option value={field.label} key={field.label}>
                          {field.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={7} lg={8}>
                  <TextField
                    variant="outlined"
                    placeholder="Keyword..."
                    value={q.value}
                    onChange={(e) => this.updateForm(e, "value", key)}
                    fullWidth
                    size="small"
                    InputProps={{
                      style: {
                        background: "#fff",
                      },
                    }}
                    // onKeyUp={(e) => this.goSearch(e)}
                  />
                </Grid>
                {key < query.length - 1 ? (
                  <Grid item xs={2}>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        native
                        fullWidth
                        value={q.condition}
                        onChange={(e) => this.updateForm(e, "condition", key)}
                        inputProps={{
                          style: {
                            background: "#fff",
                            paddingTop: "11px",
                            paddingBottom: "10px",
                          },
                        }}
                        size="small"
                      >
                        <option value="$and">AND</option>
                        <option value="$or">OR</option>
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    <IconButton
                      color="primary"
                      aria-label="add to shopping cart"
                      style={{ padding: "2px 2px", marginRight: "5px" }}
                      onClick={() => this.props.onAddQueryStack()}
                    >
                      <Icon style={{ fontSize: "2.4rem" }}>add_circle</Icon>
                    </IconButton>
                    <IconButton
                      color="secondary"
                      aria-label="add to shopping cart"
                      style={{ padding: "2px 2px" }}
                      disabled={query.length === 1}
                      onClick={() => this.props.onRemoveQueryStack()}
                    >
                      <Icon style={{ fontSize: "2.4rem" }}>remove_circle</Icon>
                    </IconButton>
                  </Grid>
                )}
              </React.Fragment>
            ))}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<Icon>search</Icon>}
              onClick={() => this.props.goSearch()}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
