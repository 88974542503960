import React, { Component } from "react";
// import Grid from '@material-ui/core/Grid';
import { getProfile } from "./js/main";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
// import IconButton from '@material-ui/core/IconButton';

import CropFreeIcon from "@material-ui/icons/CropFree";
// import CheckIcon from '@material-ui/icons/Check';
import CallMissedIcon from "@material-ui/icons/CallMissed";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Badge from '@material-ui/core/Badge';

import Paper from "@material-ui/core/Paper";
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Conf from "./Conf";
import axios from "axios";

export default class Checkout extends Component {
  pageName = "checkin";
  state = {
    cookie: JSON.parse(localStorage.getItem(Conf.cookieName)),
    items: [],
  };
  componentDidMount() {
    this.checkAuth();
    document.getElementById("barcode").value = "";
  }
  async checkAuth() {
    var profile = await getProfile();
    if (
      profile.patron &&
      profile.patron.permission &&
      profile.patron.permission.indexOf(this.pageName) > -1
    ) {
    } else window.location = process.env.PUBLIC_URL + "/login";
  }
  scan(e) {
    var barcode = e.target.value.trim();

    if (e.key === "Enter" && barcode !== "") {
      e.target.select();
      axios.get(Conf.backendURL + "item/barcode/" + barcode).then((res) => {
        var item = res.data;
        if (!item) alert("No barcode found");
        else if (item.error) alert(item.error);
        else {
          if (!item.checkout || item.checkout === "")
            alert("This item has no checkout");
          else {
            var { cookie } = this.state;
            axios
              .put(
                Conf.backendURL + "circulation/checkin/" + item.checkout,
                {},
                { headers: { Authorization: cookie.token } }
              )
              .then((res) => {
                if (res.data.error) alert(res.data.error);
                else {
                  var { items } = this.state;
                  items.push(item);
                  this.setState({ items });
                }
              });
          }
        }
      });
    }
  }
  render() {
    const { items } = this.state;
    return (
      <React.Fragment>
        <main className="content">
          <h1 className="pageHeader">
            <CallMissedIcon className="pageHeaderIcon" fontSize="large" />
            Checkin
          </h1>
          <div>
            <div
              style={{
                fontSize: "1.5em",
                marginBottom: "20px",
                fontWeight: "400",
              }}
            >
              Scan Item Code
            </div>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="barcode">Barcode</InputLabel>
              <OutlinedInput
                id="barcode"
                onKeyUp={(e) => this.scan(e)}
                startAdornment={
                  <CropFreeIcon
                    position="start"
                    style={{ marginRight: "7px" }}
                  />
                }
                labelWidth={62}
              />
            </FormControl>
          </div>
          <TableContainer component={Paper} style={{ marginTop: "20px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "80px" }}>No</TableCell>
                  <TableCell style={{ width: "150px" }}>Barcode</TableCell>
                  <TableCell>Title</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.length === 0 && (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan="3"
                      style={{ height: "80px", color: "#999" }}
                    >
                      No item found
                    </TableCell>
                  </TableRow>
                )}
                {items.map((item, key) => (
                  <TableRow key={key}>
                    <TableCell align="center">{key + 1}</TableCell>
                    <TableCell align="center">{item.barcode}</TableCell>
                    <TableCell>
                      {
                        item.biblio_id.elements.filter(
                          (j) => j.name === "title"
                        )[0].value
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </main>
      </React.Fragment>
    );
  }
}
