import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { search_key } from "../js/main";

import InputAdornment from "@material-ui/core/InputAdornment";
// import IconButton from "@material-ui/core/IconButton";
import PageFooter from "./PageFooter";

export default class SearchMainPage extends React.Component {
  goSearch(e) {
    var keyword = e.target.value.trim();
    if (e.key === "Enter" && keyword !== "") {
      var query = {
        query: [
          {
            field: document.getElementById("field").value,
            value: keyword,
          },
        ],
        skip: 0,
      };
      const encodedData = window.btoa(
        encodeURIComponent(JSON.stringify(query))
      );
      // const decodedData = window.atob(encodedData);
      // console.log(decodedData);
      this.props.history.push(
        process.env.PUBLIC_URL + "/opac/search/" + encodedData
      );
    }
  }
  render() {
    return (
      <div>
        <div
          style={{
            height: "50vh",
            minHeight: "450px",
            borderTop: "4px solid #4db6ac",
            background: "URL(./img/cover.jpg)",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              marginTop: "100px",
              //   WebkitTextStroke: "1px #666",
              textShadow: "0px 0px 5px #000",
              color: "#fff",
              fontSize: "3rem",
              fontWeight: "bold",
            }}
          >
            Happy Digital Library Search
          </h1>

          <Container maxWidth="md">
            {/* <Paper> */}
            <Grid container style={{ padding: "1px 1px" }}>
              <Grid item xs={5} md={4} lg={3}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    native
                    fullWidth
                    // onChange={handleChange}
                    //   label="Age"
                    inputProps={{
                      name: "field",
                      id: "field",
                      style: { background: "#fff" },
                    }}
                    size="large"
                  >
                    {search_key.map((field) => (
                      <option value={field.label} key={field.label}>
                        {field.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={7} md={8} lg={9} style={{ paddingLeft: "2px" }}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Keyword..."
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: { background: "#fff" },
                  }}
                  onKeyUp={(e) => this.goSearch(e)}
                />
              </Grid>
            </Grid>
            {/* </Paper> */}
          </Container>
          <br />
        </div>
        <PageFooter />
      </div>
    );
  }
}
